import Axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DropdownList } from "react-widgets";
import MsgModal from "../../../components/AlbsCmpMsgModal/AlbsCmpMsgModal";
import VaveOrderRow from "../../../components/VaveOrderRows/VaveOrderRows";
import { baseURL, merchant_id, sandbox } from "../../../fbConfig/ConfigToggle";
import firebase from "../../../fbConfig/fbConfig";
import "./OrderSelectionPage.css";

const OrderSelectionPage = (props) => {
    const [details, setDetails] = useState([]);

    const [total, setTotal] = useState(0);

    const [delCharge, setDelCharge] = useState([0, 0]);
    const [originalCharge, setOriginalCharge] = useState([0, 0]);

    const [selected, setSelected] = useState([]);

    const [loading, setLoading] = useState(false);

    const [dispatchDate, setDispatchDate] = useState(null);

    const [orderIds, setOrderIds] = useState([]);

    const [cashPay, setCashPay] = useState("Online");

    const [startDate, setStartDate] = useState(null);

    const [notifiaction, setNotification] = useState({
        notificationShow: false,
        notification: "",
        confirmed: false,
        bgColor: "",
        color: "",
        notificationFooter: false,
    });

    const [totalOrders, setTotalOrdes] = useState(0);

    /**
     * Refresh order details form
     */
    const restForm = () => {
        setCashPay("Cash");
        setDispatchDate(null);
        setSelected([]);
        setDelCharge([0, 0]);
        setTotal(0);
        setDetails([]);
        getCustomerOrders(props.cusMobile);
    };

    /**
     * Toggle notification modal when it needs
     */
    const notificationToggler = () => {
        setNotification((cur) => ({
            notificationShow: cur.notificationShow ? false : true,
        }));
    };

    const [custAddress, setcustAddress] = useState({
        houseNo: "",
        street: "",
        city: "",
        delCharge: 0,
        houseNoOffice: "",
        streetOffice: "",
        cityOffice: "",
        addressType: "Home",
    });

    /**
     * Change orders if telephone number change
     */
    useEffect(() => {
        setTotal(0);
        setSelected([]);
        if (!props.cusMobile) getCustomerOrders(props.cusMobile);
        else restForm();
    }, [props.cusMobile]);

    /**
     * Update Location price if locaiton has changed
     */
    useEffect(() => {
        firebase
            .firestore()
            .collection("location")
            .where("city", "==", custAddress.city)
            .get()
            .then((snap) => {
                if (!snap.empty) {
                    setDelCharge([parseInt(snap.docs[0].data().price), parseInt(snap.docs[0].data().price)]);
                }
            });
    }, [custAddress.city]);

    /**
     * Update total delivery dates if selection updates
     */
    useEffect(() => {
        setTotalOrdes(
            selected.filter(
                (object, index) =>
                    selected.findIndex(
                        (obj) => obj.DeliveryDate.toDateString() === object.DeliveryDate.toDateString()
                    ) === index
            ).length
        );
    }, [selected]);

    const [locations, setLocations] = useState();

    //Get Locaitons
    useEffect(() => {
        getLocations();
        setcustAddress({
            houseNo: props.inputs.houseNo,
            street: props.inputs.street,
            city: props.inputs.city,
            delCharge: 0,
            addressType: "Home",
            houseNoOffice: props.inputs.houseNoOffice,
            streetOffice: props.inputs.streetOffice,
            cityOffice: props.inputs.cityOffice,
        });
    }, []);

    /**
     * Get all the delivery locaitons
     */
    const getLocations = () => {
        firebase
            .firestore()
            .collection("location")
            .get()
            .then((snap) => {
                const arr = [];
                snap.forEach((doc) => {
                    arr.push(doc.data().city);
                });
                setLocations(() => arr.sort());
            })
            .catch((error) => console.log(error));
    };

    /**
     * Get orders related to the customers mobile using POS database
     */
    const getCustomerOrders = () => {
        setLoading(true);
        Axios.get("/posIntegration/" + props.inputs.phoneNumber)
            .then((rsp) => {
                setLoading(false);
                if (rsp.data.status === "Sucessfull") {
                    let arr = [];
                    rsp.data.data.forEach((row) => {
                        const { CO_Number, dispatch, PossibleDate, NetAmount, collectedDate, item, qty } = row;
                        let x = true;
                        arr.forEach((ele) => {
                            if (row.CO_Number === ele.CO_Number) {
                                ele.items.push({ item, qty });
                                x = false;
                            }
                        });
                        if (x) {
                            arr.push({
                                CO_Number,
                                dispatch,
                                PossibleDate: new Date(PossibleDate.substr(0, 10)),
                                DeliveryDate: new Date(PossibleDate.substr(0, 10)),
                                NetAmount,
                                collectedDate,
                                items: [{ item, qty }],
                            });
                            if (dispatch) {
                                setDispatchDate(new Date(PossibleDate.substr(0, 10)));
                                setStartDate(new Date(PossibleDate.substr(0, 10)));
                            }
                        }
                    });
                    setDetails(arr);
                } else {
                    notificationToggler();
                    setNotification((cur) => ({
                        ...cur,
                        notification: "No orders found",
                    }));
                }
            })
            .catch(() => {
                notificationToggler();
                setNotification((cur) => ({
                    ...cur,
                    notification: "Please check your internet connection",
                }));
            });

        firebase
            .firestore()
            .collection("location")
            .where("city", "==", props.inputs.city)
            .get()
            .then((snap) => {
                if (!snap.empty)
                    setDelCharge([parseInt(snap.docs[0].data().price), parseInt(snap.docs[0].data().price)]);
            });
    };

    /**
     * Update dates
     */
    const updateDate = (orderNumber, date) => {
        setDetails(
            details.map((row) => {
                if (row.CO_Number === orderNumber) return { ...row, DeliveryDate: date };
                else return row;
            })
        );
        setSelected(
            selected.map((row) => {
                if (row.CO_Number === orderNumber) return { ...row, DeliveryDate: date };
                else return row;
            })
        );
    };

    /**
     * Update dispatch date
     */
    const updateDispatchDate = (date) => {
        setStartDate(() => date);
        setDetails(
            details.map((row) => {
                if (row.dispatch) return { ...row, DeliveryDate: date };
                else return row;
            })
        );
        setSelected(
            selected.map((row) => {
                if (row.dispatch) return { ...row, DeliveryDate: date };
                else return row;
            })
        );
    };

    /**
     *
     */
    const checkSelected = (obj) => {
        let x = false;
        selected.forEach((ele) => {
            if (ele.CO_Number === obj.CO_Number) x = true;
        });
        return x;
    };

    /**
     *
     */
    const handleCheck = (e, obj) => {
        if (e.target.checked) {
            setSelected((cur) => [...cur, obj]);
            const nextTotal = total + parseInt(obj.NetAmount);
            setTotal((cur) => cur + parseInt(obj.NetAmount));
            if (nextTotal >= 2000) {
                setOriginalCharge([delCharge, delCharge]);
                setDelCharge([0, 0]);
            }
        } else {
            setSelected((cur) => cur.filter((ref) => JSON.stringify(ref) !== JSON.stringify(obj)));
            const nextTotal = total - parseInt(obj.NetAmount);
            setTotal((cur) => cur - parseInt(obj.NetAmount));
            if (nextTotal < 2000) {
                setDelCharge([originalCharge, originalCharge]);
            }
        }
    };

    /**
     * Async function for add items to an order
     */
    const addOrderItems = async (customerDetails, orderId, order) => {
        let batch = firebase.firestore().batch();
        order.items.forEach((obj) => {
            batch.set(firebase.firestore().collection("deliveryOrder").doc(), {
                customerId: customerDetails.customerRefId,
                paymentType: cashPay,
                payment: "pending",
                orderId: orderId.uniqueOrder,
                generatedId: orderId.generatedId,
                ...obj,
                createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
                name: customerDetails.name,
                phoneNumber: customerDetails.phoneNumber,
                houseNo: custAddress.addressType == "Home" ? customerDetails.houseNo : customerDetails.houseNoOffice,
                street: custAddress.addressType == "Home" ? customerDetails.street : customerDetails.streetOffice,
                city: custAddress.addressType == "Home" ? customerDetails.city : customerDetails.cityOffice,
                crBy: firebase.auth().currentUser.email,
                deliveryAddressType: custAddress.addressType,
            });
        });
        await batch.commit();
        return true;
    };

    /**
     * Async functions for add orders into OrderHeader
     */
    const addOrderUniqueData = async (customerDetails, order) => {
        let uniqueOrder = await firebase
            .firestore()
            .collection("deliveryUnique")
            .add({
                createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
                paymentType: cashPay,
                customerId: customerDetails.customerRefId,
                payment: "pending",
                name: customerDetails.name,
                phoneNumber: customerDetails.phoneNumber,
                houseNo: custAddress.addressType == "Home" ? customerDetails.houseNo : customerDetails.houseNoOffice,
                street: custAddress.addressType == "Home" ? customerDetails.street : customerDetails.streetOffice,
                city: custAddress.addressType == "Home" ? customerDetails.city : customerDetails.cityOffice,
                quantity: order.items.length,
                subtot: order.subTotal,
                item: order.items.map((obj) => {
                    return { ...obj };
                }),
                delCharge: delCharge[1],
                deliveryDate: order.DeliveryDate,
                crBy: firebase.auth().currentUser.email,
                deliveryAddressType: custAddress.addressType,
            });

        let countRef = firebase.firestore().collection("deliveryUnique").doc("--stats--");
        let orderRef = firebase.firestore().collection("deliveryUnique").doc(uniqueOrder.id);

        let idGen;
        await firebase.firestore().runTransaction((trx) => {
            return trx.get(countRef).then((countDoc) => {
                if (!countDoc.exists) throw Error("Document does not exist!");
                const nextCount = countDoc.data().count + 1;
                idGen = "00000000" + nextCount;
                idGen = "HD" + idGen.substr(idGen.length - 8);
                trx.update(orderRef, { generatedId: idGen });
                trx.update(countRef, { count: nextCount });
            });
        });

        if (uniqueOrder != undefined) {
            return { uniqueOrder: uniqueOrder.id, generatedId: idGen };
        } else {
            return false;
        }
    };

    /**
     * Async function for save customer details
     */
    const addCustomerDataToDb = async () => {
        const { phoneNumber, email, name, id: customerRefId } = props.inputs;
        let customerDoc;
        let { city, cityOffice, houseNo, houseNoOffice, street, streetOffice } = custAddress;
        if (customerRefId == null) {
            customerDoc = await firebase
                .firestore()
                .collection("customer")
                .add({
                    phoneNumber,
                    email,
                    name,
                    houseNo,
                    houseNoOffice,
                    street,
                    streetOffice,
                    city,
                    cityOffice,
                    createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
                    crBy: firebase.auth().currentUser.email,
                });
            if (customerDoc)
                return {
                    customerRefId: customerDoc.id,
                    phoneNumber,
                    email,
                    name,
                    houseNo,
                    street,
                    city,
                    houseNoOffice,
                    streetOffice,
                    cityOffice,
                };
            else return false;
        } else {
            customerDoc = await firebase
                .firestore()
                .collection("customer")
                .doc(customerRefId)
                .update({
                    name,
                    phoneNumber,
                    email,
                    houseNo,
                    street,
                    city,
                    houseNoOffice,
                    streetOffice,
                    cityOffice,
                    updatedBy: firebase.auth().currentUser.email,
                    updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
                });
            return {
                customerRefId,
                phoneNumber,
                email,
                name,
                houseNo,
                street,
                city,
                houseNoOffice,
                streetOffice,
                cityOffice,
            };
        }
    };

    /**
     * Send Email
     */
    const sendEmailToCustomer = async (cmDet, orderId, orderItems) => {
        try {
            let result = await Axios({
                method: "post",
                url: "/sendEmails/deliveryEmail",
                timeout: 1000 * 5,
                data: {
                    orderNumber: orderId.generatedId,
                    cusName: cmDet.name,
                    cusTell: cmDet.phoneNumber,
                    emailAdd: cmDet.email,
                    houseNo: custAddress.addressType == "Home" ? cmDet.houseNo : cmDet.houseNoOffice,
                    street: custAddress.addressType == "Home" ? cmDet.street : cmDet.streetOffice,
                    city: custAddress.addressType == "Home" ? cmDet.city : cmDet.cityOffice,
                    deliveryDate: orderItems.DeliveryDate,
                    collection: orderItems.items.map((obj) => {
                        return { ...obj };
                    }),
                    orderAmount: parseFloat(orderItems.subTotal).toFixed(2),
                    deliveryFee: parseFloat(delCharge[1]),
                    totalAmount: (parseFloat(orderItems.subTotal) + parseFloat(delCharge[1])).toFixed(2),
                },
            });
            return result;
        } catch (err) {
            return err;
        }
    };

    useEffect(() => {
        const totalOrders = getTotalOrders();

        if (cashPay !== "Online" && totalOrders.length === orderIds.length && orderIds.length) {
            paymentCompletetion();
        }
    }, [orderIds]);

    /* Payment Completetion */
    const paymentCompletetion = () => {
        firebase
            .firestore()
            .collection("deliveryUnique")
            .doc(orderIds[0])
            .get()
            .then((doc) => {
                if (doc.exists) {
                    if (
                        doc.data().payment === "Completed" ||
                        doc.data().paymentType === "Cash" ||
                        doc.data().paymentType === "Card"
                    ) {
                        const { phoneNumber, email, name, id: customerRefId } = props.inputs;
                        const { city, cityOffice, houseNo, houseNoOffice, street, streetOffice } = custAddress;
                        const customerDet = {
                            customerRefId,
                            phoneNumber,
                            email,
                            name,
                            houseNo,
                            street,
                            city,
                            houseNoOffice,
                            streetOffice,
                            cityOffice,
                        };

                        const totalOrders = getTotalOrders();

                        orderIds.forEach((orderId, index) => {
                            addOrderItems(customerDet, orderId, totalOrders[index]);
                            sendEmailToCustomer(customerDet, orderId, totalOrders[index]).catch(console.log);
                        });

                        setNotification((cur) => ({
                            ...cur,
                            notification: `Order has been created successfully.Thank You.`,
                            bgColor: "green",
                            color: "white",
                        }));
                    } else {
                        orderIds.forEach((orderId) =>
                            firebase.firestore().collection("deliveryUnique").doc(orderId).delete()
                        );
                    }
                }
            });
    };

    window.payhere.onCompleted = function onCompleted() {
        paymentCompletetion();
    };

    window.payhere.onDismissed = function onDismissed() {
        paymentCompletetion();
    };

    window.payhere.onError = function onError(error) {
        console.log("Error:" + error);
    };

    /* Payment Gateway Activation */
    const makePayment = (ids) => {
        const { addressType, houseNo, street, city, houseNoOffice, streetOffice, cityOffice } = custAddress;
        const { phoneNumber, email, name } = props.inputs;

        let payment = {
            sandbox,
            merchant_id,
            return_url: "",
            cancel_url: "",
            notify_url: `${baseURL}/home/payHereFuncForHd`,
            order_id: ids[0],
            items: "HOME DELIVERY",
            amount: `${(parseInt(total) + parseInt(delCharge[1].toFixed(2) * totalOrders)).toFixed(2)}`,
            currency: "LKR",
            first_name: name.split(" ")[0],
            last_name: name.split(" ")[1],
            email: email,
            phone: phoneNumber,
            address:
                addressType === "Home"
                    ? houseNo
                    : houseNoOffice
                    ? `${addressType === "Home" ? houseNo : houseNoOffice}, ${
                          addressType === "Home" ? street : streetOffice
                      }`
                    : addressType === "Home"
                    ? street
                    : streetOffice,
            city: addressType === "Home" ? city : cityOffice,
            country: "Sri Lanka",
        };
        window.payhere.startPayment(payment);
        setLoading(false);
    };

    /**
     * Create order transaction
     */
    const createOrderTransaction = async () => {
        setNotification((cur) => ({
            ...cur,
            notification: "",
            notificationFooter: false,
        }));
        if (getTotalOrders().length > 0) {
            let customerDet = await addCustomerDataToDb();
            let totalOrders = getTotalOrders().length;
            if (customerDet) {
                let orderIdArr = [];
                for (let i = 0; i < totalOrders; i++) {
                    let orderId = await addOrderUniqueData(customerDet, getTotalOrders()[i]);
                    if (orderId.uniqueOrder) {
                        orderIdArr.push(orderId.uniqueOrder);

                        if (i + 1 === totalOrders) {
                            /* Online Order */
                            if (cashPay === "Online") {
                                setOrderIds(orderIdArr);
                                makePayment(orderIdArr);
                                return true;
                            } else {
                                // useEffect
                            }
                        }
                    } else {
                        console.log(orderId);
                        setNotification((cur) => ({
                            ...cur,
                            notification: "Order cannot be completed",
                            bgColor: "red",
                            color: "white",
                        }));
                        break;
                    }
                }
            } else {
                setNotification((cur) => ({
                    ...cur,
                    notification: "Customer cannot be updated.Transaction failed.",
                    bgColor: "red",
                    color: "white",
                }));
            }
        } else {
            setNotification((cur) => ({
                ...cur,
                notification: "No orders selected",
                bgColor: "red",
                color: "white",
            }));
        }
        return false;
    };

    /**
     * Get all the unique delivery day orders from selected orders
     */
    const getTotalOrders = () => {
        let lOrder = [];
        selected
            .filter(
                (object, index) =>
                    selected.findIndex(
                        (obj) => obj.DeliveryDate.toDateString() === object.DeliveryDate.toDateString()
                    ) === index
            )
            .forEach((obj) => {
                lOrder.push({
                    DeliveryDate: obj.DeliveryDate,
                    items: selected.filter(
                        (oldObj) => oldObj.DeliveryDate.toDateString() === obj.DeliveryDate.toDateString()
                    ),
                    subTotal: selected
                        .filter((oldObj) => oldObj.DeliveryDate.toDateString() === obj.DeliveryDate.toDateString())
                        .map((item) => {
                            return item.NetAmount;
                        })
                        .reduce((a, b) => a + b, 0),
                });
            });
        return lOrder;
    };

    /**
     * Handle Checkout
     */
    const checkoutHandler = async () => {
        if (custAddress.addressType == "Home" ? !custAddress.city : !custAddress.cityOffice) {
            notificationToggler();
            setNotification((cur) => ({
                ...cur,
                notification: "Complete your address",
                bgColor: "red",
                color: "white",
            }));
        } else if (selected.length == 0) {
            notificationToggler();
            setNotification((cur) => ({
                ...cur,
                notification: "Select at least one order",
                bgColor: "red",
                color: "white",
            }));
        } else {
            notificationToggler();
            setNotification((cur) => ({
                ...cur,
                notification: "Are you sure?",
                bgColor: "#ffc107",
                color: "black",
                notificationFooter: true,
                notificationDirect: 1,
            }));
        }
    };

    /**
     * Get Confirmation
     */
    const afterConfirm = async () => {
        switch (notifiaction.notificationDirect) {
            case 1:
                if (await createOrderTransaction()) {
                    restForm();
                }
                break;
            default:
                break;
        }
    };

    /**
     * Change the delivery charge and the dropdown city when changeing the city selection dropdown
     */
    const changeCity = (value, home, office) => {
        firebase
            .firestore()
            .collection("location")
            .where("city", "==", value)
            .get()
            .then((snap) => {
                snap.forEach(() => {
                    setDelCharge([parseInt(snap.docs[0].data().price), parseInt(snap.docs[0].data().price)]);
                    setOriginalCharge([parseInt(snap.docs[0].data().price), parseInt(snap.docs[0].data().price)]);
                });
            })
            .catch((error) => console.log(error));
        if (custAddress.addressType == "Home") setcustAddress((cur) => ({ ...cur, [home]: value }));
        else setcustAddress((cur) => ({ ...cur, [office]: value }));
    };

    /**
     * customer Address change
     */
    const cusAddressChanege = (value, home, office) => {
        if (custAddress.addressType == "Home") setcustAddress((cur) => ({ ...cur, [home]: value }));
        else setcustAddress((cur) => ({ ...cur, [office]: value }));
    };

    return (
        <div className="row w-100 p-0 m-0">
            <div className="col-12 col-lg-8">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-1 text-center">
                                <i className="fas fa-file-contract fa-2x"></i>
                            </div>
                            <div className="col">
                                <div className="mb-1">
                                    <b>Select Orders</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mt-2" style={{ borderColor: "#707070" }} />
                <div className="row order-selection-div w-100 mx-1 py-1">
                    <div className="col-xs-12 col-sm-6 d-flex align-items-center">Ready to collect</div>
                    <div className="col-xs-12 col-sm-6 d-flex justify-content-sm-end align-items-center">
                        {details.find((element) => element.dispatch) && (
                            <DatePicker
                                className="rounded"
                                minDate={dispatchDate}
                                selected={startDate}
                                onChange={updateDispatchDate}
                                placeholderText="Select date"
                            />
                        )}
                    </div>
                </div>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" style={{ color: "#00468b" }} />
                    </div>
                ) : !details.find((element) => element.dispatch) ? (
                    <h6 className="mx-1 my-2 text-center">No orders</h6>
                ) : (
                    details.map((obj) => {
                        if (obj.dispatch)
                            return (
                                <VaveOrderRow
                                    dispatch={obj.dispatch}
                                    updateDate={updateDate}
                                    checked={checkSelected(obj)}
                                    items={[...obj.items]}
                                    detObj={{ ...obj }}
                                    onChange={handleCheck}
                                    key={obj.CO_Number}
                                    orderNumber={obj.CO_Number}
                                    OrderDate={obj.collectedDate.substr(0, 10)}
                                    amount={obj.NetAmount}
                                    delDate={obj.PossibleDate}
                                />
                            );
                        else return null;
                    })
                )}
                <div className="row order-selection-div2 w-100 mx-1 py-1">
                    <div className="col-xs-12 col-sm-6 d-flex align-items-center">Still washing</div>
                    <div className="col-xs-12 col-sm-6"></div>
                </div>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" style={{ color: "#00468b" }} />
                    </div>
                ) : !details.find((element) => !element.dispatch) ? (
                    <h6 className="mx-1 my-2 text-center">No orders</h6>
                ) : (
                    details.map((obj) => {
                        if (!obj.dispatch)
                            return (
                                <VaveOrderRow
                                    dispatch={obj.dispatch}
                                    updateDate={updateDate}
                                    checked={checkSelected(obj)}
                                    items={[...obj.items]}
                                    detObj={{ ...obj }}
                                    onChange={handleCheck}
                                    key={obj.CO_Number}
                                    orderNumber={obj.CO_Number}
                                    OrderDate={obj.collectedDate.substr(0, 10)}
                                    amount={obj.NetAmount}
                                    delDate={obj.PossibleDate}
                                />
                            );
                        else return null;
                    })
                )}
            </div>
            <div className="col-12 col-lg-4 my-5 my-lg-0 ">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-1 text-center">
                                <i className="fas fa-file-contract fa-2x"></i>
                            </div>
                            <div className="col">
                                <div className="mb-1">
                                    <b>Delivery Address</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mt-2" style={{ borderColor: "#707070" }} />
                <div className="form-group row">
                    <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">
                        Destination
                    </label>
                    <div className="col-sm-8">
                        <DropdownList
                            value={custAddress.addressType}
                            onChange={(type) => {
                                setcustAddress((cur) => ({ ...cur, addressType: type }));
                            }}
                            filter
                            data={["Home", "Office"]}
                            style={{ width: "100%", height: "31px", fontSize: "13px" }}
                            placeholder="Address Type"
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">
                        House No
                    </label>
                    <div className="col-sm-8">
                        <input
                            className="form-control form-control-sm"
                            id="colFormLabelSm1"
                            value={custAddress.addressType === "Home" ? custAddress.houseNo : custAddress.houseNoOffice}
                            placeholder="House Number"
                            onChange={(e) => cusAddressChanege(e.target.value, "houseNo", "houseNoOffice")}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">
                        Street
                    </label>
                    <div className="col-sm-8">
                        <input
                            className="form-control form-control-sm"
                            id="colFormLabelSm2"
                            value={custAddress.addressType === "Home" ? custAddress.street : custAddress.streetOffice}
                            placeholder="Street"
                            onChange={(e) => cusAddressChanege(e.target.value, "street", "streetOffice")}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="colFormLabelSm" className="col-sm-4 col-form-label col-form-label-sm">
                        City
                    </label>
                    <div className="col-sm-8">
                        <DropdownList
                            value={custAddress.addressType === "Home" ? custAddress.city : custAddress.cityOffice}
                            onChange={(city) => {
                                changeCity(city, "city", "cityOffice");
                            }}
                            filter
                            data={locations}
                            style={{ width: "100%", height: "31px", fontSize: "13px" }}
                            placeholder="City"
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-12 col-md-12">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-1 text-center">
                                <i className="fas fa-file-contract fa-2x"></i>
                            </div>
                            <div className="col">
                                <div className="mb-1">
                                    <b>Billing Summary</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mt-2" style={{ borderColor: "#707070" }} />
                <table className="table-sm table-striped w-100">
                    <tbody>
                        <tr>
                            <td>Number of Orders</td>
                            <td className="text-right pr-4">{selected.length}</td>
                        </tr>
                        <tr>
                            <td>Total Delivery Amount</td>
                            <td className="text-right pr-3">{(delCharge[1] * totalOrders).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>Total Order Amount</td>
                            <td className="text-right pr-3">{total !== 0 ? total.toFixed(2) : "0.00"}</td>
                        </tr>
                        <tr style={{ height: "50px", fontWeight: "bold" }}>
                            <td>Grand Total</td>
                            <td className="text-right pr-3">
                                {total !== 0
                                    ? (parseInt(total) + parseInt(delCharge[1].toFixed(2) * totalOrders)).toFixed(2)
                                    : "0.00"}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" style={{ backgroundColor: "#004B90", color: "white" }}>
                                Payment Method
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="row">
                                <div className="input-group py-2 w-100">
                                    <div className="col-12 input-group-prepend ml-md-2 align-items-center">
                                        <input
                                            onChange={() => setCashPay(() => "Online")}
                                            defaultChecked
                                            className="mr-1"
                                            type="radio"
                                            name="payment-method-del-only"
                                            aria-label="Radio button for following text input"
                                        />
                                        Pay online
                                    </div>
                                    <div className="col-12 input-group-prepend ml-md-2 align-items-center">
                                        <input
                                            onChange={() => setCashPay(() => "Cash")}
                                            className="mr-1"
                                            type="radio"
                                            name="payment-method-del-only"
                                            aria-label="Radio button for following text input"
                                        />
                                        Pay on delivery - Cash
                                    </div>
                                    <div className="col-12 input-group-prepend ml-md-2 align-items-center">
                                        <input
                                            onChange={() => setCashPay(() => "Card")}
                                            className="mr-1"
                                            type="radio"
                                            name="payment-method-del-only"
                                            aria-label="Radio button for following text input"
                                        />
                                        Pay on delivery - Card
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="text-center">
                                <button
                                    onClick={checkoutHandler}
                                    className="btn btn-danger btn-sm w-100 "
                                    type="submit"
                                >
                                    Place Order
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <MsgModal
                show={notifiaction.notificationShow}
                onHide={notificationToggler}
                message={notifiaction.notification}
                bgColor={notifiaction.bgColor}
                color={notifiaction.color}
                footerShow={notifiaction.notificationFooter}
                confirmBtnOnClick={afterConfirm}
            />
        </div>
    );
};

export default OrderSelectionPage;
