import React from "react";
import "./VaveButton01.css";

const VaveButton01 = (props) => {
    return (
        <div
            type="button"
            className="btn vaveButton01_container"
            onClick={props.onClick}
            style={{ backgroundColor: "#004c98" }}
        >
            {props.title}
        </div>
    );
};

export default VaveButton01;
