import "@fontsource/montserrat";
import "@fontsource/roboto";
import "@fontsource/roboto-slab";
import Axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./components/AuthContext/AuthContext";
import { OrderProvider } from "./components/OrderContext/OrderContext";
import { baseURL } from "./fbConfig/ConfigToggle";
import * as serviceWorker from "./serviceWorker";

Axios.defaults.baseURL = `${baseURL}/lmatApi`;

const app = (
    <Router>
        <OrderProvider>
            <AuthProvider>
                <App />
            </AuthProvider>
        </OrderProvider>
    </Router>
);

ReactDOM.render(app, document.getElementById("root"));

serviceWorker.register();
