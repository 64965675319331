import React, { useContext, useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import AccountHomeDel from "../../components/AccountHomeDel/AccountHomeDel";
import AccountOutlet from "../../components/AccountOutlet/AccountOutlet";
import AccountPickup from "../../components/AccountPickup/AccountPickup";
import AccountSettings from "../../components/AccountSettings/AccountSettings";
import { AuthContext } from "../../components/AuthContext/AuthContext";
import { auth, db } from "../../fbConfig/fbConfig";
import "./CustomerAccount.css";

const CustomerAccount = (props) => {
    const history = useHistory();

    const [sideNav, setSideNav] = props.useSideNav;

    const [navs, setNavs] = useState(2);

    const [cities, setCities] = useState([]);

    const [holder, setHolder] = useState(false);

    const [details, setDetails] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        houseNo: "",
        street: "",
        city: "",
        email: "",
        houseNoOffice: "",
        streetOffice: "",
        cityOffice: "",
        id: "",
    });

    const [pickupOrders, setPickupOrders] = useState([
        {
            generatedId: "",
            deliveryDate: "",
            pickupDate: "",
            orderDate: "",
            deliveryComplete: "",
            pickupComplete: "",
            pickupRejected: "",
            item: [],
            quantity: "",
            subtot: "",
        },
    ]);

    const [homedelOrders, setHomedelOrders] = useState([
        {
            CO_Number: "",
            createdAt: "",
            DeliveryDate: "",
            collectedDate: "",
            dispatch: "",
            deliveryComplete: "",
            orderDeliveredAt: "",
        },
    ]);

    const user = useContext(AuthContext);

    const signOut = () => auth.signOut().then(() => history.push("/"));

    const [load, setLoad] = useState(false);

    useEffect(() => {
        if (user && user !== "INIT") {
            db.collection("customer")
                .doc(user.uid)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        setDetails({
                            ...doc.data(),
                            id: user.uid,
                        });
                    }
                })
                .catch(console.log);

            db.collection("order")
                .where("customerId", "==", user.uid)
                .get()
                .then((set) => {
                    let arr = [];
                    set.forEach((doc) => {
                        arr.push(doc.data());
                    });
                    setPickupOrders(arr);
                    setLoad(true);
                })
                .catch(console.log);

            db.collection("deliveryUnique")
                .where("customerId", "==", user.uid)
                .get()
                .then((set) => {
                    let arr = [];
                    set.forEach((doc) => {
                        arr.push(doc.data());
                    });
                    setHomedelOrders(arr);
                    setLoad(true);
                })
                .catch(console.log);

            db.collection("location")
                .orderBy("city")
                .get()
                .then((snap) => {
                    const arr = [];
                    snap.forEach((doc) => {
                        arr.push(doc.data().city);
                    });
                    setCities(arr);
                });
        }

        if (!user) history.push("/");
    }, [user]);

    const widthSelector = () => setHolder(window.innerWidth <= 768);

    useEffect(() => {
        window.addEventListener("resize", widthSelector);
        widthSelector();
    }, []);

    if (!load)
        return (
            <div className="d-flex w-100 justify-content-center align-items-center mt-5 pt-5">
                <Spinner animation="border" style={{ color: "#00468b", height: "3rem", width: "3rem" }} />
            </div>
        );

    return (
        <div>
            {!holder && setSideNav(true)}
            <div className="customer-account-sidenav" style={{ width: sideNav ? "300px" : "0" }}>
                <div>
                    <div className="customer-account-imgsec">
                        <i
                            className="fas fa-user-circle fa-2x text-black-50"
                            style={{ paddingBottom: "20px", paddingTop: "7px" }}
                        ></i>
                        <div className="customer-login-details">
                            <div className="font-weight-bold">{details.name}</div>
                            <div>{details.phoneNumber}</div>
                        </div>
                    </div>
                    <div
                        className={`customer-account-link ${navs === 1 ? "customer-account-link-active" : ""}`}
                        onClick={() => {
                            setNavs(1);
                            holder && setSideNav(false);
                        }}
                    >
                        <i className="fas fa-users-cog mr-3"></i>
                        Change Settings
                    </div>
                    <div className="customer-account-link" onClick={signOut}>
                        <i className="fas fa-power-off mr-3"></i>
                        Sign Out
                    </div>
                </div>
                <div className="customer-account-nav-vl"></div>
                <div>
                    <div className="customer-account-link-header">My Orders</div>
                    <div
                        className={`customer-account-link ${navs === 2 ? "customer-account-link-active" : ""}`}
                        onClick={() => {
                            setNavs(2);
                            holder && setSideNav(false);
                        }}
                    >
                        <i className="fas fa-shipping-fast mr-3"></i>
                        Pickup & Home Delivery
                    </div>
                    <div
                        className={`customer-account-link ${navs === 3 ? "customer-account-link-active" : ""}`}
                        onClick={() => {
                            setNavs(3);
                            holder && setSideNav(false);
                        }}
                    >
                        <i className="fas fa-truck mr-3"></i>
                        Home Delivery Only
                    </div>
                    <div
                        className={`customer-account-link ${navs === 4 ? "customer-account-link-active" : ""}`}
                        onClick={() => {
                            setNavs(4);
                            holder && setSideNav(false);
                        }}
                    >
                        <i className="fas fa-home mr-3"></i>
                        Create Home Delivery
                    </div>
                </div>

                <div className="customer-account-nav-bottom">
                    <div className="customer-account-nav-vl"></div>
                    <div className="customer-account-link-header  pb-1">Menu</div>
                    <div
                        className={`customer-account-link ${navs === 5 ? "customer-account-link-active" : ""}`}
                        onClick={() => {
                            history.push("./");
                            holder && setSideNav(false);
                            setNavs(5);
                        }}
                    >
                        <i className="fas fa-home mr-3"></i>
                        Home
                    </div>
                    <div className="customer-account-link">
                        <i className="fas fa-phone-volume mr-3"></i>
                        Contact Us
                    </div>
                </div>
            </div>

            <div className="customer-account-main">
                {navs === 1 && (
                    <AccountSettings uid={user.uid} details={{ ...details }} setDetails={setDetails} cities={cities} />
                )}
                {navs === 2 && <AccountPickup orders={[...pickupOrders]} />}
                {navs === 3 && <AccountHomeDel orders={[...homedelOrders]} />}
                {navs === 4 && <AccountOutlet inputs={details} />}
            </div>
        </div>
    );
};

export default CustomerAccount;
