import React, { useEffect, useState } from "react";
import { Alert, Button, FormControl, InputGroup } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { DropdownList } from "react-widgets";
import firebase from "../../fbConfig/fbConfig";
import "./AccountSettings.css";

const AccountSettings = (props) => {
    const [holder, setHolder] = useState(false);

    const [show, setShow] = useState(false);

    const [updateDisable, setUpdateDisable] = useState(true);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (show) setTimeout(() => setShow(false), 3000);
    }, [show]);

    const [details, setDetails] = useState(props.details);

    const setValue = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value });
        setUpdateDisable(false);
    };

    const updateDetails = () => {
        firebase
            .firestore()
            .collection("customer")
            .doc(props.uid)
            .update({
                ...details,
            })
            .then(function () {
                props.setDetails({
                    ...details,
                });
                setLoading(false);
                setShow(true);
            })
            .catch(console.log);
    };

    const widthSelector = () => setHolder(window.innerWidth <= 768);

    useEffect(() => {
        window.addEventListener("resize", widthSelector);
        widthSelector();
    }, []);

    return (
        <div>
            <div className="customer-account-link customer-account-title">
                <i className="fas fa-users-cog mr-3"></i>
                Account Settings
            </div>
            <div className="account-settings-container">
                <div className="mt-3 mb-2">Contact Details</div>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend hidden={holder}>
                        <InputGroup.Text
                            className=" justify-content-center account-settings-prepend"
                            style={{ width: "100px" }}
                        >
                            Mobile No
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl value={details.phoneNumber} placeholder={holder ? "Mobile No" : ""} disabled />
                </InputGroup>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend hidden={holder}>
                        <InputGroup.Text
                            className=" m-0 justify-content-center account-settings-prepend"
                            style={{ width: "100px" }}
                        >
                            Email
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl value={details.email} placeholder={holder ? "Email" : ""} disabled />
                </InputGroup>
                <div className="mt-3 mb-2">Home Address</div>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend className=" d-none d-sm-flex">
                        <InputGroup.Text
                            className=" m-0 justify-content-center account-settings-prepend"
                            style={{ width: "100px" }}
                        >
                            House No
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="houseNo"
                        value={details.houseNo}
                        placeholder={holder ? "House No" : ""}
                        onChange={setValue}
                    />
                </InputGroup>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend className=" d-none d-sm-flex">
                        <InputGroup.Text
                            className=" m-0 justify-content-center account-settings-prepend"
                            style={{ width: "100px" }}
                        >
                            Street
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="street"
                        value={details.street}
                        placeholder={holder ? "Street" : ""}
                        onChange={setValue}
                    />
                </InputGroup>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend className=" d-none d-sm-flex">
                        <InputGroup.Text
                            className=" m-0 justify-content-center account-settings-prepend"
                            style={{ width: "100px" }}
                        >
                            City
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <DropdownList
                        onChange={(city) => {
                            setDetails({
                                ...details,
                                city,
                            });
                            setUpdateDisable(false);
                        }}
                        value={details.city}
                        filter
                        data={props.cities}
                        style={{
                            width: holder ? "100%" : "calc(100% - 100px)",
                        }}
                        placeholder={holder ? "City" : ""}
                    />
                </InputGroup>

                <div className="mt-3 mb-2">Office Address</div>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend className=" d-none d-sm-flex">
                        <InputGroup.Text
                            className=" m-0 justify-content-center account-settings-prepend"
                            style={{ width: "100px" }}
                        >
                            House No
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="houseNoOffice"
                        value={details.houseNoOffice}
                        placeholder={holder ? "House No" : ""}
                        onChange={setValue}
                    />
                </InputGroup>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend className=" d-none d-sm-flex">
                        <InputGroup.Text
                            className=" m-0 justify-content-center account-settings-prepend"
                            style={{ width: "100px" }}
                        >
                            Street
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="streetOffice"
                        value={details.streetOffice}
                        placeholder={holder ? "Street" : ""}
                        onChange={setValue}
                    />
                </InputGroup>
                <InputGroup className="mb-4">
                    <InputGroup.Prepend className=" d-none d-sm-flex">
                        <InputGroup.Text
                            className=" m-0 justify-content-center account-settings-prepend"
                            style={{ width: "100px" }}
                        >
                            City
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <DropdownList
                        onChange={(cityOffice) => {
                            setDetails({
                                ...details,
                                cityOffice,
                            });
                            setUpdateDisable(false);
                        }}
                        value={details.cityOffice}
                        filter
                        data={props.cities}
                        style={{
                            width: holder ? "100%" : "calc(100% - 100px)",
                        }}
                        placeholder={holder ? "City" : ""}
                    />
                </InputGroup>
                {show && !loading && (
                    <Alert variant="success" className="mt-3 text-center">
                        Successfully Updated
                    </Alert>
                )}
                {loading && (
                    <div className="mt-3 d-flex justify-content-center">
                        <Spinner animation="border" style={{ color: "#00468b" }} />
                    </div>
                )}
                <div className="d-flex justify-content-end">
                    <Button
                        onClick={() => {
                            setUpdateDisable(true);
                            setLoading(true);
                            updateDetails();
                        }}
                        disabled={updateDisable}
                    >
                        Update
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AccountSettings;
