import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import firebase from "../../fbConfig/fbConfig";
import { OrderContext } from "../OrderContext/OrderContext";
import drycleaning from "./images/drycleaning.png";
import folded from "./images/folded.png";
import hanged from "./images/hanged.png";
import pressing from "./images/pressing.png";
import washing from "./images/washing.png";
import "./VaveItemCard.css";

const VaveItemCard = (props) => {
    /* Item count */
    const [count, setCount] = useState(0);

    const [note, setNote] = useState("");

    const [show, setShow] = useState(false);

    /* Modal close */
    const handleClose = () => {
        setShow(false);
        setCount(0);
        setMethod(0);
        setForm(0);
    };

    /* Modal open */
    const handleShow = () => setShow(true);

    const [price, setPrice] = useState(props.item.washingPrice);

    const [method, setMethod] = useState(0);

    const [orders, setOrders] = useContext(OrderContext);

    const handleMethod = (selectedIndex) => {
        setMethod(selectedIndex);
        const { washingPrice, pressingPrice, drycleaningPrice } = props.item;
        switch (selectedIndex) {
            case 0:
                setPrice(washingPrice);
                break;
            case 1:
                setPrice(pressingPrice);
                break;
            case 2:
                setPrice(drycleaningPrice);
                break;
            default:
                break;
        }
    };

    const [form, setForm] = useState(0);

    const handleForm = (selectedIndex) => {
        setForm(selectedIndex);
    };

    const addItem = () => {
        let deliveryform = form === 0 ? "folded" : "hanged";

        let washingmethod = method === 0 ? "washing" : method === 1 ? "pressing" : "drycleaning";

        const { name, washingPrice, pressingPrice, drycleaningPrice, completeDays } = props.item;

        if (count !== 0) {
            let state = true;

            setOrders(
                orders.map((row) => {
                    if (row.item === name && row.washingmethod === washingmethod && row.deliveryform === deliveryform) {
                        state = false;
                        return {
                            ...row,
                            quantity: row.quantity + count,
                            subtot: row.price * (row.quantity + count),
                            note: note ? note : row.note,
                        };
                    } else return row;
                })
            );

            if (state)
                setOrders([
                    ...orders,
                    {
                        id: name + washingmethod + deliveryform,
                        item: name,
                        washingmethod,
                        deliveryform,
                        price,
                        quantity: count,
                        subtot: price * count,
                        washingPrice,
                        pressingPrice,
                        drycleaningPrice,
                        image: props.item.image,
                        note,
                        completeDays,
                    },
                ]);
        }

        setShow(false);
        setCount(0);
        setMethod(0);
        setForm(0);
    };

    const countChange = (e) => {
        if (-1 < e.target.value && e.target.value < 1001) setCount(e.target.value);
    };

    //Add images state
    const [fbImage, setFbImage] = useState();

    //Get image and add it to location
    const getImage = () => {
        var arr = props.item.image.split("/");
        var starsRef = firebase.storage().ref("lmatWeb/itemImages");
        starsRef
            .child(arr[6])
            .getDownloadURL()
            .then(function (url) {
                setFbImage(url);
            })
            .catch(console.log);
    };

    //Download images when page loding
    useEffect(() => {
        getImage();
    }, []);

    const carouselNext = (
        <svg
            className="bi bi-arrow-right-circle-fill"
            width="1.5em"
            height="1.5em"
            viewBox="0 0 16 16"
            fill="blue"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8.354 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L9.793 7.5H5a.5.5 0 0 0 0 1h4.793l-2.147 2.146z"
            />
        </svg>
    );

    const carouselPrev = (
        <svg
            className="bi bi-arrow-left-circle-fill"
            width="1.5em"
            height="1.5em"
            viewBox="0 0 16 16"
            fill="blue"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.646 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L6.207 7.5H11a.5.5 0 0 1 0 1H6.207l2.147 2.146z"
            />
        </svg>
    );

    return (
        <div className=" col-6 col-lg-3 col-sm-4 col-xl-2 py-4 mx-0 mx-xl-2">
            <div
                className="rounded shadow d-flex align-items-end vavecard-item-img"
                style={{
                    height: "200px",
                    backgroundImage: `url(${fbImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <div
                    className="row rounded d-flex text-white w-100 vavecard-item-img-name-card"
                    style={{
                        backgroundColor: "rgba(62,77,113,0.7)",
                        height: "85px",
                        margin: "0",
                    }}
                >
                    <div className="col-7 d-flex flex-column justify-content-center align-items-center vavecard-item-img-name">
                        <span className="text-uppercase " style={{ fontSize: "12px", fontWeight: "bold" }}>
                            {props.item.name}
                        </span>
                    </div>
                    <div className="col-5 d-flex justify-content-center align-items-center">
                        <button
                            onClick={handleShow}
                            className="btn btn-primary button-add-media-query"
                            style={{ fontSize: "12px" }}
                        >
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                backdrop="static"
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "22px" }}>Customize your order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-6 col-12 modal-image-background-fit">
                            <div
                                className="sm-rounded sm-shadow d-flex align-items-end modelImage"
                                style={{
                                    backgroundImage: `url(${fbImage})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                }}
                            >
                                <div
                                    className="row sm-rounded d-flex text-white w-100 modal-item-name-card"
                                    style={{
                                        backgroundColor: "#3e4d71",
                                        height: "125px",
                                        margin: "0",
                                        fontSize: "12px",
                                    }}
                                >
                                    <div className="col-lg-6 col-12 d-flex flex-column justify-content-center align-items-center">
                                        <p
                                            style={{ fontSize: "15px" }}
                                            className="text-uppercase font-weight-bold modal-item-name"
                                        >
                                            {props.item.name}
                                        </p>
                                        <p style={{ fontSize: "13px" }}>{props.item.completeDays} days to process</p>
                                    </div>
                                    <div className="col-lg-6 col-12 d-flex justify-content-center align-items-center">
                                        <p className="h5">LKR {price}.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-12">
                            <p className="h6 carousel-topic">Select Method</p>
                            <Carousel
                                activeIndex={method}
                                onSelect={handleMethod}
                                interval={null}
                                indicators={false}
                                prevIcon={carouselPrev}
                                nextIcon={carouselNext}
                            >
                                <Carousel.Item>
                                    <img
                                        className="mx-auto d-block carousel-image-mob-view "
                                        height="65px"
                                        src={washing}
                                        alt=""
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="mx-auto d-block carousel-image-mob-view"
                                        height="65px"
                                        src={pressing}
                                        alt=""
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="mx-auto d-block carousel-image-mob-view"
                                        height="65px"
                                        src={drycleaning}
                                        alt=""
                                    />
                                </Carousel.Item>
                            </Carousel>
                            <p className="h6 carousel-topic">Select Form</p>
                            <Carousel
                                activeIndex={form}
                                onSelect={handleForm}
                                interval={null}
                                indicators={false}
                                prevIcon={carouselPrev}
                                nextIcon={carouselNext}
                            >
                                <Carousel.Item>
                                    <img
                                        className="mx-auto d-block carousel-image-mob-view"
                                        height="65px"
                                        src={folded}
                                        alt=""
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="mx-auto d-block carousel-image-mob-view"
                                        height="65px"
                                        src={hanged}
                                        alt=""
                                    />
                                </Carousel.Item>
                            </Carousel>
                            <p className="h6 carousel-topic mt-2">Special Notes?</p>
                            <div>
                                <textarea
                                    style={{ fontSize: "15px" }}
                                    onChange={(e) => setNote(e.target.value)}
                                    className="w-100"
                                />
                            </div>
                            <div className="row">
                                <div
                                    className="text-center justify-content-center col-6 col-sm-12 item-counter-mob-view"
                                    style={{ paddingTop: "20px" }}
                                >
                                    <svg
                                        className="bi bi-dash-square-fill item-count-button"
                                        width="1.5em"
                                        height="1.5em"
                                        viewBox="0 0 16 16"
                                        fill="#004C98"
                                        xmlns="http://www.w3.org/2000/svg"
                                        onClick={() =>
                                            setCount(count !== 0 ? parseInt(count !== "" ? count : 0) - 1 : 0)
                                        }
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2 7.5a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1H4z"
                                        />
                                    </svg>
                                    <input
                                        type="text"
                                        size="1"
                                        value={count}
                                        className="Item-count-number-mob"
                                        onChange={countChange}
                                        style={{
                                            border: "none",
                                            textAlign: "center",
                                            outlineStyle: "none",
                                        }}
                                    />
                                    <svg
                                        className="bi bi-plus-square-fill item-count-button"
                                        width="1.5em"
                                        height="1.5em"
                                        viewBox="0 0 16 16"
                                        fill="#004C98"
                                        xmlns="http://www.w3.org/2000/svg"
                                        onClick={() =>
                                            setCount(count !== 1000 ? parseInt(count !== "" ? count : 0) + 1 : 1000)
                                        }
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z"
                                        />
                                    </svg>
                                </div>
                                <div className="d-sm-none col-6 my-3 text-right">
                                    <Button variant="primary" onClick={addItem} className="btn-add-to-cart-mob-view">
                                        Add to basket
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-none d-sm-flex">
                    <Button style={{ fontSize: "15px" }} variant="primary" onClick={addItem}>
                        Add to basket
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default VaveItemCard;
