import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { OrderContext } from "../../../components/OrderContext/OrderContext";
import SimpleSlider from "../../../components/SimpleSlider/SimpleSlider";
import VaveItemCard from "../../../components/VaveItemCard/VaveItemCard";
import { db } from "../../../fbConfig/fbConfig";
import "./PickupMain.css";

const PickupMain = () => {
    const history = useHistory();

    /* All items */
    const [items, setItems] = useState([]);

    /* Getting all items */
    useEffect(() => {
        db.collection("item")
            .orderBy("name")
            .get()
            .then((snap) => {
                let arr = [];
                snap.forEach((doc) => arr.push(doc.data()));
                setItems(arr);
            });
        let items = sessionStorage.getItem("order_details");
        setOrders(JSON.parse(items ? items : "[]"));

        window.scrollTo(0, 0);
    }, []);

    /* Item category */
    const [category, setCategory] = useState("Favourites");

    /* Selecting category */
    const updateCategory = (category) => setCategory(category);

    const [orders, setOrders] = useContext(OrderContext);

    const [summaryTotal, setSummaryTotal] = useState(0);

    useEffect(() => {
        sessionStorage.setItem("order_details", orders.length !== 0 ? JSON.stringify(orders) : "[]");

        setSummaryTotal(orders.reduce((acc, curr) => acc + curr.subtot, 0));
    }, [orders]);

    const toBasket = () => history.push("/basket");

    return (
        <div className="col align p-0">
            <div className="container-fluid bg-light pickupMain_main">
                <div className="row mr-xs-0 ml-xs-0" style={{ marginBottom: "100px" }}>
                    <div className="col-12 ">
                        <div style={{ margin: "20px 5px" }} className="row justify-content-center">
                            <div className="col-12">
                                <div className="d-flex justify-content-center">
                                    <div className="pickupMain_simpleSlider">
                                        <SimpleSlider updateCategory={updateCategory} category={category} />
                                    </div>
                                </div>
                            </div>
                            {items.map(
                                (item, index) =>
                                    item.category === category && <VaveItemCard key={index} item={{ ...item }} />
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className="fixed-bottom row align-items-center pt-2"
                    style={{ height: "90px", backgroundColor: "#004b90" }}
                >
                    <div
                        className="col-7 col-sm-5 col-md-4 offset-sm-6 offset-4 offset-md-7  text-right"
                        style={{ color: "white", fontSize: "18px" }}
                    >
                        Total : LKR {summaryTotal.toFixed(2)}
                    </div>
                    <div className="w-100"></div>
                    <div className=" offset-lg-9 offset-sm-8 offset-6 col-5 col-sm-3 col-lg-2 text-right">
                        <button
                            type="button"
                            className="btn btn-light mb-2 px-2 py-1"
                            onClick={toBasket}
                            style={{ fontWeight: "bold" }}
                        >
                            Your Basket
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PickupMain;
