import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import firebase, { auth } from "../../fbConfig/fbConfig";
import LoginCSS from "./LoginModal.module.css";

const LoginSection = ({ renderReset, handleClose }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(false);

    const [error, setError] = useState("");
    const [spinner, setSpinner] = useState(false);

    const forgotPassword = (e) => {
        e.preventDefault();
        renderReset();
    };

    const logIn = async () => {
        setSpinner(true);
        setError("");
        const trimmedMail = email.trim();
        try {
            const result = await auth.fetchSignInMethodsForEmail(trimmedMail);
            if (result[0] === "google.com") {
                let provider = new firebase.auth.GoogleAuthProvider();
                await auth.signInWithPopup(provider);
            } else if (result[0] === "facebook.com") {
                let provider = new firebase.auth.FacebookAuthProvider();
                await auth.signInWithPopup(provider);
            } else {
                await auth.setPersistence(firebase.auth.Auth.Persistence[remember ? "LOCAL" : "SESSION"]);
                await auth.signInWithEmailAndPassword(trimmedMail, password);
            }
            handleClose();
            setSpinner(false);
        } catch (error) {
            console.log(error);
            setSpinner(false);
            setError("Invalid email or password");
        }
        setTimeout(() => {
            setError("");
        }, 5000);
    };

    return (
        <div className="mb-3">
            {error && (
                <Alert className="text-center" variant="danger">
                    {error}
                </Alert>
            )}
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className="fas fa-at"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className="fas fa-lock"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </InputGroup>

            <div className={LoginCSS.input_wrap}>
                <Row>
                    <Col sm={6}>
                        <Form.Check
                            label="Remember me"
                            name="remember"
                            checked={remember}
                            onChange={(e) => setRemember(e.target.checked)}
                        />
                    </Col>
                    <Col sm={6}>
                        <a href="!#" onClick={forgotPassword}>
                            Forgot password?
                        </a>
                    </Col>
                </Row>
            </div>
            <div className={LoginCSS.button_wrap}>
                {!spinner ? (
                    <Button className={LoginCSS.log_button} onClick={logIn}>
                        Log in
                    </Button>
                ) : (
                    <Spinner animation="border" style={{ color: "#00468b" }} />
                )}
            </div>
        </div>
    );
};

export default LoginSection;
