import React, { useEffect, useState } from "react";
import "./AccountOrderRow.css";

const AccountOrderRow = (props) => {
    const [show, setShow] = useState(false);

    const customDateFormat = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const [rowData, setRowData] = useState(["", "", ""]);

    useEffect(() => {
        switch (props.type) {
            case "pickup-ongoing":
                setRowData([
                    customDateFormat(props.orderRow.pickupDate.toDate()),
                    customDateFormat(props.orderRow.deliveryDate.toDate()),
                    props.orderRow.pickupComplete ? "Collected" : "New",
                ]);
                break;
            case "pickup-completed":
                setRowData([
                    customDateFormat(props.orderRow.orderDate.toDate()),
                    customDateFormat(props.orderRow.pickupDate.toDate()),
                    customDateFormat(props.orderRow.deliveryDate.toDate()),
                ]);
                break;
            default:
                setRowData(["", "", ""]);
                break;
        }
    }, []);

    return (
        <>
            <div className="row custom-table-striped-tr">
                <div className="col-1 custom-table-striped-td">{props.index + 1}</div>
                <div className="col custom-table-striped-td">{props.orderRow.generatedId}</div>
                <div className="col custom-table-striped-td d-none d-sm-block">{rowData[0]}</div>
                <div className="col custom-table-striped-td d-none d-sm-block">{rowData[1]}</div>
                <div className="col custom-table-striped-td">{rowData[2]}</div>
                <div className="col-1 custom-table-striped-td">
                    <i
                        className={`fas fa-chevron-circle-${!show ? "down" : "up"}`}
                        role="button"
                        onClick={() => setShow(!show)}
                    ></i>
                </div>
            </div>
            {show && (
                <div className="row">
                    <div className="col-sm-3 col-lg-6"></div>
                    <div className="col-sm-9 col-lg-6 container custom-table-striped">
                        <div className="row custom-table-striped-th font-weight-bold">
                            <div className="col-1 custom-table-striped-td">#</div>
                            <div className="col custom-table-striped-td">Item</div>
                            <div className="col custom-table-striped-td text-right">Amount</div>
                        </div>
                        {props.orderRow.item?.map((obj, index) => (
                            <div className="row custom-table-striped-tr" key={index.toString()}>
                                <div className="col-1 custom-table-striped-td">{index + 1}</div>
                                <div className="col custom-table-striped-td">{obj.item}</div>
                                <div className="col custom-table-striped-td text-right">{obj.subtot}</div>
                            </div>
                        ))}
                        <div className="row">
                            <div className="col-xl-4"></div>
                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col">Total Pcs</div>
                                    <div className="col text-right">{props.orderRow.quantity}</div>
                                </div>
                                <div className="row">
                                    <div className="col">Delivery Charge</div>
                                    <div className="col text-right">{(+props.orderRow.delCharge).toFixed(2)}</div>
                                </div>
                                <div className="row">
                                    <div className="col">Total Amount</div>
                                    <div className="col text-right">
                                        {(+props.orderRow.subtot + +props.orderRow.delCharge).toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AccountOrderRow;
