import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import { auth } from "../../fbConfig/fbConfig";
import LoginCSS from "./LoginModal.module.css";

const PasswordReset = ({ renderLogin }) => {
    const [email, setEmail] = useState("");

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [spinner, setSpinner] = useState(false);

    const sendEmail = async () => {
        setSpinner(true);
        setSuccess("");
        setError("");
        try {
            await auth.sendPasswordResetEmail(email.trim());
            setSpinner(false);
            setError("");
            setSuccess("Successfully sent");
        } catch (error) {
            setSpinner(false);
            setSuccess("");

            if (error.code === "auth/user-not-found") setError("Invalid user");
            else setError("Invalid email");
        }
        setTimeout(() => {
            setSuccess("");
            setError("");
        }, 5000);
    };

    return (
        <div style={{ padding: 30 }}>
            <div className="position-relative text-center mb-3" style={{ fontSize: 18 }}>
                <span style={{ position: "absolute", left: 0 }} role="button" onClick={renderLogin}>
                    <i className="fas fa-arrow-left"></i>
                </span>
                Enter email for password reset
            </div>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className="fas fa-at"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </InputGroup>
            <div className="d-flex justify-content-end mb-3">
                {!spinner ? (
                    <Button className={LoginCSS.log_button} onClick={sendEmail}>
                        Send email
                    </Button>
                ) : (
                    <Spinner animation="border" style={{ color: "#00468b" }} />
                )}
            </div>

            {success && (
                <Alert variant="success" className="text-center">
                    {success}
                </Alert>
            )}

            {error && (
                <Alert variant="danger" className="text-center">
                    {error}
                </Alert>
            )}
        </div>
    );
};

export default PasswordReset;
