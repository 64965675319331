import React from "react";
import OrderSection from "../BackendOnCallHd/VaveContOrderSelection/OrderSelectionPage";
import "./AccountOutlet.css";

const AccountOutlet = (props) => {
    return (
        <div>
            <div>
                <div className="customer-account-link customer-account-title">
                    <i className="fas fa-home mr-3"></i>
                    Create Home Delivery
                </div>
                <div className="container">
                    <div className="w-100 mt-3">
                        <OrderSection
                            inputs={props.inputs}
                            location={props.inputs.city}
                            cusMobile={props.inputs.phoneNumber}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountOutlet;
