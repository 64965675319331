import React, { useContext, useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import firebase from "../../fbConfig/fbConfig";
import { OrderContext } from "../OrderContext/OrderContext";
import "./EachRow.css";
import drycleaning from "./images/drycleaning.png";
import folded from "./images/folded.png";
import hanged from "./images/hanged.png";
import pressing from "./images/pressing.png";
import washing from "./images/washing.png";

const EachRow = (props) => {
    const [amount, setAmount] = useState(props.order.quantity);

    const [value] = useState(props.order.washingmethod);

    const [valueform] = useState(props.order.deliveryform);

    const [method, setMethod] = useState();

    const [form, setForm] = useState();

    const [orders, setOrders] = useContext(OrderContext);

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const updateBasket = (item, qty, washingmethod) => {
        setOrders(
            orders.map((row) => {
                if (
                    row.id === props.order.id &&
                    row.item === item &&
                    row.washingmethod === washingmethod &&
                    row.deliveryform === props.order.deliveryform
                )
                    return { ...row, quantity: qty, subtot: qty * row.price };
                else return row;
            })
        );
    };

    useEffect(() => {
        switch (value) {
            case "washing":
                setMethod(0);
                break;
            case "pressing":
                setMethod(1);
                break;
            case "drycleaning":
                setMethod(2);
                break;
            default:
                break;
        }
        switch (valueform) {
            case "hanged":
                setForm(0);
                break;
            case "folded":
                setForm(1);
                break;
            default:
                break;
        }
    }, []);

    const handleMethod = (selectedIndex) => {
        setMethod(selectedIndex);
        let washingmethod = selectedIndex === 0 ? "washing" : selectedIndex === 1 ? "pressing" : "drycleaning";
        setOrders(
            orders.map((row) => {
                if (
                    row.id === props.order.id &&
                    row.item === props.order.item &&
                    row.washingmethod === props.order.washingmethod &&
                    row.deliveryform === props.order.deliveryform
                )
                    return {
                        ...row,
                        washingmethod,
                        price: row[`${washingmethod}Price`],
                        subtot: row.quantity * row[`${washingmethod}Price`],
                    };
                else return row;
            })
        );
    };

    const handleForm = (selectedIndex) => {
        setForm(selectedIndex);
        let deliveryform = selectedIndex === 0 ? "hanged" : "folded";
        setOrders(
            orders.map((row) => {
                if (
                    row.id === props.order.id &&
                    row.item === props.order.item &&
                    row.washingmethod === props.order.washingmethod &&
                    row.deliveryform === props.order.deliveryform
                )
                    return { ...row, deliveryform };
                else return row;
            })
        );
    };

    const quantityChange = (val) => {
        if (-1 < val.target.value && val.target.value < 1001) {
            setAmount(val.target.value === 0 ? 1 : val.target.value);
            updateBasket(props.order.item, val.target.value, props.order.washingmethod);
        }
    };

    const incrementChange = () => {
        let amt = amount !== 1000 ? parseInt(amount !== "" ? amount : 0) + 1 : 1000;
        setAmount(amt);
        updateBasket(props.order.item, amt, props.order.washingmethod);
    };

    const decrementChange = () => {
        let amt = amount !== 1 ? parseInt(amount !== "" ? amount : 2) - 1 : 1;
        setAmount(amt);
        updateBasket(props.order.item, amt, props.order.washingmethod);
    };

    const deleteRow = () => {
        setOrders(
            orders.filter(
                (row) =>
                    row.item !== props.order.item ||
                    row.washingmethod !== props.order.washingmethod ||
                    row.deliveryform !== props.order.deliveryform
            )
        );
        handleClose();
    };

    //Add images state
    const [, setFbImage] = useState();

    //Get image and add it to location
    const getImage = () => {
        var arr = props.order.image.split("/");
        var starsRef = firebase.storage().ref("lmatWeb/itemImages");
        starsRef
            .child(arr[6])
            .getDownloadURL()
            .then(function (url) {
                setFbImage(url);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    //Download images when page loding
    useEffect(() => {
        getImage();
    }, []);

    const carouselNext = (
        <svg
            className="bi bi-arrow-right-circle-fill"
            width="1.5em"
            height="1.5em"
            viewBox="0 0 16 16"
            fill="blue"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8.354 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L9.793 7.5H5a.5.5 0 0 0 0 1h4.793l-2.147 2.146z"
            />
        </svg>
    );

    const carouselPrev = (
        <svg
            className="bi bi-arrow-left-circle-fill"
            width="1.5em"
            height="1.5em"
            viewBox="0 0 16 16"
            fill="blue"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.646 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L6.207 7.5H11a.5.5 0 0 1 0 1H6.207l2.147 2.146z"
            />
        </svg>
    );

    return (
        <tr>
            <td className="orderRow-item align-middle">
                <div className="orderRowItem_normalItem">{props.order.item}</div>
            </td>

            <td className="orderRow-itemplustotal text-left px-2 align-middle" rowSpan="2">
                <div className="d-flex flex-row mb-2">
                    <div style={{ width: "50%", paddingRight: "5%" }}>Item Name</div>
                    <div
                        className="orderRowItem_modelButton"
                        onClick={handleShow}
                        style={{ width: "50%", paddingLeft: "5%" }}
                    >
                        {props.order.item}
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div style={{ width: "50%", paddingRight: "5%" }}>Amount</div>
                    <div style={{ width: "50%", paddingLeft: "5%" }}>{props.order.subtot}</div>
                </div>
            </td>

            <td className="orderRow-washingMethod align-middle">
                <Carousel
                    activeIndex={method}
                    onSelect={handleMethod}
                    interval={null}
                    indicators={false}
                    prevIcon={<i className="fas fa-chevron-left text-dark"></i>}
                    nextIcon={<i className="fas fa-chevron-right text-dark"></i>}
                >
                    <Carousel.Item disabled={true} style={{ paddingTop: "15px" }}>
                        <p>Washing</p>
                    </Carousel.Item>
                    <Carousel.Item style={{ paddingTop: "15px" }}>
                        <p>Pressing</p>
                    </Carousel.Item>
                    <Carousel.Item style={{ paddingTop: "15px" }}>
                        <p>Dry Cleaning</p>
                    </Carousel.Item>
                </Carousel>
            </td>

            <td className="deliveryForm-normal align-middle">
                <Carousel
                    activeIndex={form}
                    onSelect={handleForm}
                    interval={null}
                    indicators={false}
                    prevIcon={<i className="fas fa-chevron-left text-dark"></i>}
                    nextIcon={<i className="fas fa-chevron-right text-dark"></i>}
                >
                    <Carousel.Item style={{ paddingTop: "15px" }}>
                        <p>Hanged</p>
                    </Carousel.Item>
                    <Carousel.Item style={{ paddingTop: "15px" }}>
                        <p>Folded</p>
                    </Carousel.Item>
                </Carousel>
            </td>

            <td className="orderRow-price align-middle">{props.order.price}</td>

            <td className="oderRow-quantity align-middle">
                <div className="quantityInput-large">
                    <svg
                        className="bi bi-dash-square-fill"
                        width="1.7em"
                        height="1.7em"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={decrementChange}
                    >
                        <path
                            fillRule="evenodd"
                            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2 7.5a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1H4z"
                        />
                    </svg>
                    <input type="text" className="quantityInput" value={amount} onChange={quantityChange}></input>
                    <svg
                        className="bi bi-plus-square-fill"
                        width="1.7em"
                        height="1.7em"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={incrementChange}
                    >
                        <path
                            fillRule="evenodd"
                            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z"
                        />
                    </svg>
                </div>
            </td>

            <td className="orderRow-subtotal align-middle">{props.order.subtot}</td>

            <td className="orderRow-bin align-middle">
                <i className="fas fa-trash-alt" style={{ fontSize: "20px" }} onClick={deleteRow}></i>
            </td>

            <Modal
                backdrop="static"
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update your order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-6 my-4">
                            <div
                                className="rounded shadow d-flex align-items-end "
                                style={{
                                    height: "250px",
                                    backgroundImage: `url(${props.order.image})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                }}
                            >
                                <div
                                    className="row rounded d-flex text-white w-100"
                                    style={{
                                        backgroundColor: "#3e4d71",
                                        height: "100px",
                                        margin: "0",
                                    }}
                                >
                                    <div className="col-6 d-flex flex-column justify-content-center">
                                        <p className="text-uppercase font-weight-bold">{props.order.item}</p>
                                    </div>
                                    <div className="col-6 d-flex justify-content-center align-items-center">
                                        <p className="h5">Rs. {props.order.price}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mb-4">
                            <p className="h5">Select Method</p>
                            <Carousel
                                activeIndex={method}
                                onSelect={handleMethod}
                                interval={null}
                                indicators={false}
                                prevIcon={carouselPrev}
                                nextIcon={carouselNext}
                            >
                                <Carousel.Item>
                                    <img className="mx-auto d-block" height="65px" src={washing} alt="" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="mx-auto d-block" height="65px" src={pressing} alt="" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="mx-auto d-block" height="65px" src={drycleaning} alt="" />
                                </Carousel.Item>
                            </Carousel>
                            <p className="h5 mt-3">Select Form</p>
                            <Carousel
                                activeIndex={form}
                                onSelect={handleForm}
                                interval={null}
                                indicators={false}
                                prevIcon={carouselPrev}
                                nextIcon={carouselNext}
                            >
                                <Carousel.Item>
                                    <img className="mx-auto d-block" height="65px" src={hanged} alt="" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="mx-auto d-block" height="65px" src={folded} alt="" />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </tr>
    );
};

export default EachRow;
