import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import { auth, db } from "../../fbConfig/fbConfig";
import LoginCSS from "./LoginModal.module.css";

const SignupSection = ({ sendVerification, setDetails: setSuperDetails, renderCode }) => {
    const [details, setDetails] = useState({
        first_name: "",
        last_name: "",
        phoneNumber: "",
        email: "",
        password: "",
        confirm_password: "",
        remember: false,
    });

    const [error, setError] = useState({
        first_name: "",
        last_name: "",
        phoneNumber: "",
        email: "",
        password: "",
        confirm_password: "",
    });

    const [spinner, setSpinner] = useState(false);

    const setValue = (e) => {
        e.persist();
        setDetails((details) => ({ ...details, [e.target.name]: e.target.value }));
    };

    const checkValidity = async () => {
        let first_name, last_name, phoneNumber, email, password, confirm_password;

        let mobileRegEx = /^[0][7][01245678][0-9]{7}$/;
        let stringRegEx = /^[A-Za-z.]+$/;

        /* name validation */
        if (stringRegEx.test(details.first_name)) first_name = "";
        else first_name = "Invalid name";
        if (stringRegEx.test(details.last_name)) last_name = "";
        else last_name = "Invalid name";

        /* mobile number validation */
        if (!mobileRegEx.test(details.phoneNumber)) phoneNumber = "Invalid mobile number";
        else {
            const result = await db.collection("customer").where("phoneNumber", "==", details.phoneNumber).get();
            phoneNumber = !result.empty ? "This number can not be used" : "";
        }

        /* email validation */
        try {
            const result = await auth.fetchSignInMethodsForEmail(details.email);
            if (result.length !== 0) email = "Email already in use";
            else email = "";
        } catch (error) {
            email = "Invalid email";
        }

        /* password validation */
        if (details.password.length < 6) password = "Password must contain at least 6 character";
        else password = "";

        /* confirm password validation */
        if (details.confirm_password.length < 6)
            confirm_password = "Confirm password must contain at least 6 character";
        else if (details.password === details.confirm_password) confirm_password = "";
        else confirm_password = "Passwords to not match";

        const errorObject = { first_name, last_name, phoneNumber, email, password, confirm_password };
        setError(errorObject);
        return !Object.values(errorObject).some((element) => element);
    };

    const signUp = async () => {
        setSpinner(true);
        if (await checkValidity()) {
            const { first_name, last_name, phoneNumber, email, password, remember } = details;
            sendVerification(phoneNumber);
            setSuperDetails({
                provider: false,
                name: `${first_name} ${last_name}`,
                phoneNumber,
                email,
                password,
                remember,
            });
            renderCode();
            setSpinner(false);
        } else setSpinner(false);
    };

    return (
        <div className="mb-3">
            <Form.Row>
                <Col sm={6} className="mb-3">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-user"></i>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="First name"
                            name="first_name"
                            isInvalid={error.first_name}
                            value={details.first_name}
                            onChange={setValue}
                        />
                        <Form.Control.Feedback type="invalid">{error.first_name}</Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col sm={6} className="mb-3">
                    {/* <FormControl
                        placeholder="Last name"
                        name="last_name"
                        isInvalid={error.last_name}
                        value={details.last_name}
                        onChange={setValue}
                    /> */}
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-user"></i>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Last name"
                            name="last_name"
                            isInvalid={error.last_name}
                            value={details.last_name}
                            onChange={setValue}
                        />
                        <Form.Control.Feedback type="invalid">{error.last_name}</Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Form.Row>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className="fas fa-phone-alt"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    placeholder="Mobile"
                    name="phoneNumber"
                    isInvalid={error.phoneNumber}
                    value={details.phoneNumber}
                    onChange={setValue}
                />
                <Form.Control.Feedback type="invalid">{error.phoneNumber}</Form.Control.Feedback>
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className="fas fa-at"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    placeholder="Email"
                    name="email"
                    isInvalid={error.email}
                    value={details.email}
                    onChange={setValue}
                />
                <Form.Control.Feedback type="invalid">{error.email}</Form.Control.Feedback>
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className="fas fa-lock"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    type="password"
                    placeholder="Password"
                    name="password"
                    isInvalid={error.password}
                    value={details.password}
                    onChange={setValue}
                />
                <Form.Control.Feedback type="invalid">{error.password}</Form.Control.Feedback>
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className="fas fa-lock"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    type="password"
                    placeholder="Confirm password"
                    name="confirm_password"
                    isInvalid={error.confirm_password}
                    value={details.confirm_password}
                    onChange={setValue}
                />
                <Form.Control.Feedback type="invalid">{error.confirm_password}</Form.Control.Feedback>
            </InputGroup>

            <div className={LoginCSS.input_wrap}>
                <Form.Check
                    label="Remember me"
                    name="remember"
                    value={details.remember}
                    onChange={(e) => {
                        e.persist();
                        setDetails((details) => ({ ...details, remember: e.target.checked }));
                    }}
                />
            </div>
            <div className={LoginCSS.button_wrap}>
                {!spinner ? (
                    <Button className={LoginCSS.log_button} onClick={signUp}>
                        Sign up
                    </Button>
                ) : (
                    <Spinner animation="border" style={{ color: "#00468b" }} />
                )}
            </div>
        </div>
    );
};

export default SignupSection;
