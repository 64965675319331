import React, { useContext } from "react";
import EachRow from "../EachRow/EachRow";
import { OrderContext } from "../OrderContext/OrderContext";
import "./Records.css";

const Records = () => {
    const [orders] = useContext(OrderContext);

    return (
        <div className="col-12 table-responsive p-0">
            <table
                id="add-row"
                className="display table table-striped table-hover shadow-none px-0 w-100"
                style={{ textAlign: "center" }}
            >
                <tbody style={{ border: 0 }}>
                    <tr style={{ fontWeight: "bold" }}>
                        <td className="orderDetails_headerItem align-middle">Item</td>
                        <td className="orderDetails_headerWashingMethod align-middle">Washing Method</td>
                        <td className="orderDetails_headerDeliveryForm align-middle">Delivery Form</td>
                        <td className="orderDetails_headerPrice align-middle">
                            Unit Price
                            <br />
                            LKR
                        </td>
                        <td className="orderDetails_headerQuantity align-middle">Quantity</td>
                        <td className="orderDetails_headerTotal align-middle">
                            Total
                            <br />
                            LKR
                        </td>
                        <td className="orderDetails_headerBin align-middle"></td>
                    </tr>

                    {orders.map((x, index) => (
                        <EachRow key={index} order={{ ...x }} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Records;
