import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import AccountOrderRow from "../AccountOrderRow/AccountOrderRow";

const AccountPickup = (props) => {
    const ongoingCondition = (obj) =>
        (!obj.deliveryComplete && !obj.pickupRejected) ||
        (!obj.deliveryComplete && obj.pickupRejected && obj.pickupCompleted);

    return (
        <div>
            <div className="customer-account-link customer-account-title">
                <i className="fas fa-shipping-fast mr-3"></i>
                Pickup & Home Delivery
            </div>
            <div className="container">
                <Tabs defaultActiveKey="ongoing" id="uncontrolled-tab-example">
                    <Tab eventKey="ongoing" title="Ongoing Orders">
                        {props.orders.filter(ongoingCondition).length === 0 ? (
                            <div className="row mt-5 text-center">
                                <div
                                    className="col justify-content-center"
                                    style={{
                                        fontSize: "18px",
                                        color: "#818181",
                                        fontWeight: "bold",
                                    }}
                                >
                                    No ongoing orders.
                                    <br />
                                    <Link to="/" style={{ textDecoration: "underline", color: "#818181" }}>
                                        Place an order
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div className="container custom-table-striped">
                                <div className="row custom-table-striped-th font-weight-bold">
                                    <div className="col-1 custom-table-striped-td">#</div>
                                    <div className="col custom-table-striped-td">Order Number</div>
                                    <div className="col custom-table-striped-td d-none d-sm-block">Pickup Date</div>
                                    <div className="col custom-table-striped-td d-none d-sm-block">Delivery Date</div>
                                    <div className="col custom-table-striped-td">Status</div>
                                    <div className="col-1 custom-table-striped-td"></div>
                                </div>
                                {props.orders.filter(ongoingCondition).map((obj, index) => (
                                    <AccountOrderRow
                                        type="pickup-ongoing"
                                        orderRow={{ ...obj }}
                                        key={index.toString()}
                                        index={index}
                                    />
                                ))}
                            </div>
                        )}
                    </Tab>
                    <Tab eventKey="completed" title="Completed Orders">
                        {props.orders.filter((obj) => obj.deliveryComplete).length === 0 ? (
                            <div className="row mt-5 text-center">
                                <div
                                    className="col justify-content-center"
                                    style={{
                                        fontSize: "18px",
                                        color: "#818181",
                                        fontWeight: "bold",
                                    }}
                                >
                                    No completed orders.
                                </div>
                            </div>
                        ) : (
                            <div className="container custom-table-striped">
                                <div className="row custom-table-striped-th font-weight-bold">
                                    <div className="col-1 custom-table-striped-td">#</div>
                                    <div className="col custom-table-striped-td">Order Number</div>
                                    <div className="col custom-table-striped-td d-none d-sm-block">Ordered Date</div>
                                    <div className="col custom-table-striped-td d-none d-sm-block">Pickup Date</div>
                                    <div className="col custom-table-striped-td">Delivered Date</div>
                                    <div className="col-1 custom-table-striped-td"></div>
                                </div>
                                {props.orders
                                    .filter((obj) => obj.deliveryComplete)
                                    .map((obj, index) => (
                                        <AccountOrderRow
                                            type="pickup-completed"
                                            orderRow={{ ...obj }}
                                            key={index.toString()}
                                            index={index}
                                        />
                                    ))}
                            </div>
                        )}
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};

export default AccountPickup;
