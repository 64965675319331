import { GoogleApiWrapper } from "google-maps-react";
import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { DropdownList } from "react-widgets";
import "react-widgets/styles.css";
import truck from "../../../assets/images/delivery-truck.png";
import { AuthContext } from "../../../components/AuthContext/AuthContext";
import "../../../components/Checkout/card.css";
import { OrderContext } from "../../../components/OrderContext/OrderContext";
import VaveButton01 from "../../../components/VaveButton01/VaveButton01";
import { baseURL, merchant_id, sandbox } from "../../../fbConfig/ConfigToggle";
import firebase from "../../../fbConfig/fbConfig";
import "./form.css";

const Form = () => {
    const [, setlocationNotification] = useState("");

    const history = useHistory();

    const [subtotal, setSubtotal] = useState(0);

    const [qty, setQty] = useState();

    const [orderId, setOrderId] = useState();

    const [genId, setGenId] = useState();

    const [show, setShow] = useState(false);

    const handleOk = () => {
        sessionStorage.removeItem("order_details");
        sessionStorage.removeItem("customer_details");
        setShow(false);
        history.push("/");
    };

    const [loading, setLoading] = useState(false);

    const [locations, setLocations] = useState({ city: [], price: [] });

    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        houseNo: "",
        street: "",
        city: "",
        landmark: { lat: 0, lng: 0 },
        houseNoOffice: "",
        streetOffice: "",
        cityOffice: "",
        email: "",
        phoneNumber: "",
        delIns: "",
        delCharge: 0,
        pickupDate: new Date(),
        deliveryDate: new Date(),
        discount: 0,
        paymentType: "",
    });

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const [validation, setValidation] = useState({
        firstName: "form-control",
        lastName: "form-control",
        street: "form-control",
        city: "form-control",
        email: "form-control",
        phoneNumber: "form-control",
    });

    const [deliveryGap, setDeliveryGap] = useState(3);

    /* Google maps integration */

    useEffect(() => {
        // navigator.geolocation.getCurrentPosition((position) => {
        //   setValues((values) => ({
        //     ...values,
        //     landmark: {
        //       lat: position.coords.latitude,
        //       lng: position.coords.longitude,
        //     },
        //   }));
        // });
    }, []);
    /* Google maps integration */

    const [minDate, setMinDate] = useState(new Date());

    const updateDate = (date) => {
        setValues((values) => ({ ...values, pickupDate: date }));
        let changDate = new Date(date);
        changDate.setDate(changDate.getDate() + deliveryGap);
        setMinDate(changDate);
        setValues((values) => ({ ...values, deliveryDate: changDate }));
    };

    const ExampleCustomInput = ({ value, onClick }) => (
        <button type="button" className="checkout-card-card-date-button" onClick={onClick}>
            {value}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
        </button>
    );

    const [orders, setOrders] = useContext(OrderContext);

    const user = useContext(AuthContext);

    useEffect(() => {
        let total = 0;
        let qty = 0;
        orders.forEach((x) => {
            total += x.subtot;
            qty += x.quantity;
        });
        setQty(qty);
        setSubtotal(total);
    }, [orders]);

    useEffect(() => {
        if (user !== "INIT" && user) {
            firebase
                .firestore()
                .collection("location")
                .orderBy("city")
                .get()
                .then((snap) => {
                    const arr = { city: [], price: [] };
                    snap.forEach((doc) => {
                        arr.city.push(doc.data().city);
                        arr.price.push(doc.data().price);
                    });
                    setLocations(arr);

                    firebase
                        .firestore()
                        .collection("customer")
                        .doc(user.uid)
                        .get()
                        .then((doc) => {
                            if (doc.exists) {
                                let {
                                    email,
                                    phoneNumber,
                                    houseNo,
                                    street,
                                    city,
                                    landmark,
                                    houseNoOffice,
                                    cityOffice,
                                    streetOffice,
                                } = doc.data();

                                let name = doc.data().name.split(" ");

                                let order_details = sessionStorage.getItem("order_details");
                                setOrders(JSON.parse(order_details ? order_details : "[]"));
                                if (order_details) {
                                    let max = 3;
                                    JSON.parse(order_details).forEach((row) => {
                                        if (row.completeDays > max) max = row.completeDays;
                                    });
                                    setDeliveryGap(parseInt(max));
                                }

                                const subtotal = JSON.parse(order_details ? order_details : "[]").reduce(
                                    (prev, curr) => prev + curr.subtot,
                                    0
                                );

                                setValues((values) => ({
                                    ...values,
                                    city,
                                    email,
                                    houseNo,
                                    landmark,
                                    firstName: name[0],
                                    lastName: name[1],
                                    street,
                                    phoneNumber,
                                    cityOffice,
                                    houseNoOffice,
                                    streetOffice,
                                    delCharge:
                                        subtotal < 1000
                                            ? arr.price[arr.city.indexOf(city)]
                                                ? arr.price[arr.city.indexOf(city)]
                                                : 0
                                            : 0,
                                }));

                                setDeldetails({
                                    ...deldetails,
                                    houseNo,
                                    street,
                                    city,
                                    houseNoOffice,
                                    streetOffice,
                                    cityOffice,
                                    delCharge:
                                        subtotal < 1000
                                            ? arr.price[arr.city.indexOf(city)]
                                                ? arr.price[arr.city.indexOf(city)]
                                                : 0
                                            : 0,
                                    delChargeOffice:
                                        subtotal < 1000
                                            ? arr.price[arr.city.indexOf(cityOffice)]
                                                ? arr.price[arr.city.indexOf(cityOffice)]
                                                : 0
                                            : 0,
                                });
                            }
                        });
                })
                .catch((error) => console.log(error));

            window.scrollTo(0, 0);
        }

        if (!user) history.push("/");
    }, [user]);

    useEffect(() => {
        updateDate(new Date(new Date().setDate(new Date().getDate() + 1)));
    }, [deliveryGap]);

    const [deldetails, setDeldetails] = useState({
        destination: "Home",
        houseNo: "",
        street: "",
        city: "",
        houseNoOffice: "",
        streetOffice: "",
        cityOffice: "",
        delCharge: "",
        delChargeOffice: "",
    });

    const onValChange = (e) => {
        setDeldetails({ ...deldetails, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        if (delPayMethod !== "Online" && orderId) {
            paymentCompletetion();
        }
    }, [orderId]);

    const paymentCompletetion = () => {
        const retWashingType = (str) => {
            switch (str) {
                case "washing":
                    return "Washing";
                case "pressing":
                    return "Pressing";
                case "drycleaning":
                    return "Dry Cleaning";
            }
        };
        firebase
            .firestore()
            .collection("order")
            .doc(orderId)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    if (
                        doc.data().payment === "Completed" ||
                        doc.data().paymentType === "Cash" ||
                        doc.data().paymentType === "Card"
                    ) {
                        const db = firebase.firestore();
                        const countRef = db.collection("order").doc("--stats--");
                        const orderRef = db.collection("order").doc(orderId);

                        let idGen;
                        return db
                            .runTransaction((trx) => {
                                return trx.get(countRef).then((countDoc) => {
                                    if (!countDoc.exists) throw Error("Document does not exist!");
                                    const nextCount = countDoc.data().count + 1;
                                    idGen = "00000000" + nextCount;
                                    idGen = "PD" + idGen.substr(idGen.length - 8);
                                    trx.update(orderRef, {
                                        generatedId: idGen,
                                    });
                                    trx.update(countRef, { count: nextCount });
                                    setGenId(idGen);
                                });
                            })
                            .then(() => {
                                const { destination, houseNo, street, city, houseNoOffice, streetOffice, cityOffice } =
                                    deldetails;
                                setShow(true);
                                fetch(`${baseURL}/lmatApi/sendEmails/pickupEmailWithItems`, {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        orderNumber: idGen,
                                        cusName: `${values.firstName} ${values.lastName}`,
                                        cusTell: values.phoneNumber,
                                        emailAdd: values.email,
                                        houseNo: destination === "Home" ? houseNo : houseNoOffice,
                                        street: destination === "Home" ? street : streetOffice,
                                        city: destination === "Home" ? city : cityOffice,
                                        // landmark: values.landmark,
                                        deliveryDate: values.deliveryDate.toDateString(),
                                        pickupDate: values.pickupDate.toDateString(),
                                        collection: orders.map((item) => ({
                                            itemName: item.item,
                                            washingType: retWashingType(item.washingmethod),
                                            deliveryForm: item.deliveryform === "hanged",
                                            qty: item.quantity,
                                            itemUnitPrice: item.price,
                                            totalAmount: item.subtot,
                                        })),
                                        orderAmount: parseInt(subtotal).toFixed(2),
                                        deliveryFee: parseInt(values.delCharge).toFixed(2),
                                        discountAmount: (parseInt(subtotal) * parseInt(values.discount) * 0.01).toFixed(
                                            2
                                        ),
                                        totalAmount: (
                                            parseInt(values.delCharge) +
                                            parseInt(subtotal) * (1 - parseInt(values.discount) * 0.01)
                                        ).toFixed(2),
                                    }),
                                });
                            })
                            .catch((error) => console.log("Transaction failed: ", error));
                    } else {
                        firebase.firestore().collection("order").doc(orderId).delete();
                        firebase
                            .firestore()
                            .collection("orderItem")
                            .where("orderId", "==", orderId)
                            .get()
                            .then((snap) => {
                                snap.forEach((doc) => {
                                    doc.ref.delete();
                                });
                            });
                    }
                }
            });
    };

    window.payhere.onCompleted = function onCompleted() {
        paymentCompletetion();
    };

    window.payhere.onDismissed = function onDismissed() {
        paymentCompletetion();
    };

    window.payhere.onError = function onError(error) {
        console.log("Error:" + error);
    };

    const makePayment = (id) => {
        const { destination, houseNo, street, city, houseNoOffice, streetOffice, cityOffice } = deldetails;
        let payment = {
            sandbox,
            merchant_id,
            return_url: "",
            cancel_url: "",
            notify_url: `${baseURL}/home/payHereFunc`,
            order_id: id,
            items: "PICKUP AND DELIVERY",
            amount: `${parseInt(values.delCharge) + parseInt(subtotal) * (1 - parseInt(values.discount) * 0.01)}`,
            currency: "LKR",
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            phone: values.phoneNumber,
            address:
                destination === "Home"
                    ? houseNo
                    : houseNoOffice
                    ? `${destination === "Home" ? houseNo : houseNoOffice}, ${
                          destination === "Home" ? street : streetOffice
                      }`
                    : destination === "Home"
                    ? street
                    : streetOffice,
            city: destination === "Home" ? city : cityOffice,
            country: "Sri Lanka",
        };
        window.payhere.startPayment(payment);
        setLoading(false);
    };

    const placeOrder = () => {
        if (deldetails.destination === "Home") {
            setValidation((validation) =>
                !deldetails.street
                    ? { ...validation, street: "form-control is-invalid" }
                    : { ...validation, street: "form-control" }
            );
            setValidation((validation) =>
                !deldetails.city
                    ? { ...validation, city: "form-control is-invalid" }
                    : { ...validation, city: "form-control" }
            );
        } else {
            setValidation((validation) =>
                !deldetails.streetOffice
                    ? { ...validation, street: "form-control is-invalid" }
                    : { ...validation, street: "form-control" }
            );
            setValidation((validation) =>
                !deldetails.cityOffice
                    ? { ...validation, city: "form-control is-invalid" }
                    : { ...validation, city: "form-control" }
            );
        }

        if (
            (deldetails.destination === "Home" && deldetails.street && deldetails.city) ||
            (deldetails.destination === "Office" && deldetails.streetOffice && deldetails.cityOffice)
        ) {
            setLoading(true);
            let db = firebase.firestore();
            db.collection("customer")
                .doc(user.uid)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        const {
                            phoneNumber,
                            email,
                            firstName,
                            lastName,
                            // landmark,
                        } = values;

                        const {
                            destination,
                            houseNo,
                            street,
                            city,
                            houseNoOffice,
                            streetOffice,
                            cityOffice,
                            delCharge,
                            delChargeOffice,
                        } = deldetails;

                        setValues({
                            ...values,
                            delCharge: destination === "Home" ? delCharge : delChargeOffice,
                        });

                        doc.ref.update({
                            email,
                            name: `${firstName} ${lastName}`,
                            houseNo,
                            street,
                            city,
                            houseNoOffice,
                            streetOffice,
                            cityOffice,
                            // landmark,
                        });

                        db.collection("order")
                            .add({
                                customerId: doc.id,
                                delCharge: values.delCharge,
                                deliveryDate: values.deliveryDate,
                                orderDate: firebase.firestore.Timestamp.fromDate(new Date()),
                                payment: "pending",
                                paymentType: delPayMethod,
                                pickupDate: values.pickupDate,
                                name: `${firstName} ${lastName}`,
                                phoneNumber,
                                deliveryAddressType: destination,
                                houseNo: destination === "Home" ? houseNo : houseNoOffice,
                                street: destination === "Home" ? street : streetOffice,
                                city: destination === "Home" ? city : cityOffice,
                                quantity: qty,
                                subtot: parseInt(subtotal).toFixed(2),
                                item: orders.map((order) => {
                                    const { deliveryform, item, price, quantity, note, subtot, washingmethod } = order;
                                    return {
                                        deliveryform,
                                        item,
                                        price,
                                        quantity,
                                        remarks: note,
                                        subtot,
                                        washingmethod,
                                    };
                                }),
                            })
                            .then((doc) => {
                                let batch = firebase.firestore().batch();
                                orders.forEach((order) => {
                                    const { deliveryform, item, note, quantity, washingmethod, price, subtot } = order;
                                    batch.set(db.collection("orderItem").doc(), {
                                        deliveryform,
                                        item,
                                        note,
                                        orderId: doc.id,
                                        quantity,
                                        washingmethod,
                                        price,
                                        subtot,
                                    });
                                });
                                batch.commit();

                                setOrderId(doc.id);

                                if (delPayMethod === "Online") {
                                    makePayment(doc.id);
                                } else {
                                    // useEffect
                                }
                            });
                    }
                });
        } else {
            window.scrollTo(0, 0);
        }
    };

    const [delPayMethod, setDelPayMethod] = useState("Online");

    return (
        <div className="container-fluid bg-white">
            <div className="row justify-content-center">
                <div
                    className="justify-content-center align-items-center d-flex"
                    style={{
                        backgroundColor: "#F5F5F5",
                        height: "63px",
                        width: "100%",
                        position: "fixed",
                        zIndex: "1",
                        top: "70px",
                    }}
                >
                    <img src={truck} alt="" style={{ height: "26px" }} className="mr-3" />
                    <span style={{ fontSize: "18px", color: "#727272" }}>Checkout</span>
                </div>
                <div className="col-12 col-lg-7 mt-4 pl-md-5 pr-md-0 px-sm-5 px-3 form-left-summary">
                    <div className="card px-sm-5 px-2" style={{ border: "1px solid #e3e3e3" }}>
                        <span className="text-center my-2" style={{ Color: "#e3e3e3", fontSize: "17px" }}>
                            Recipient Details
                        </span>
                        <hr className=" mt-0 mx-3" style={{ borderColor: "#e3e3e3" }} />
                        <div className="card-body">
                            <div className="form-row ">
                                <div className="col-lg-4 col-md-6 mb-3 ">
                                    <label htmlFor="validationDefault01">First Name</label>
                                    <input
                                        className={validation.firstName}
                                        value={values.firstName}
                                        type="text"
                                        name="firstName"
                                        onChange={(e) => setValues({ ...values, firstName: e.target.value })}
                                    />
                                </div>
                                <div className="col-lg-4 mb-3 col-md-6 mx-auto">
                                    <label htmlFor="validationDefault02">Last Name</label>
                                    <input
                                        className={validation.lastName}
                                        value={values.lastName}
                                        type="text"
                                        name="lastName"
                                        onChange={(e) => setValues({ ...values, lastName: e.target.value })}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <label htmlFor="validationDefault02">Mobile Number</label>
                                    <input
                                        className={validation.phoneNumber}
                                        value={values.phoneNumber}
                                        type="text"
                                        name="phoneNumber"
                                        readOnly
                                    />
                                </div>

                                <div className="col-lg-6 col-md-6 mb-3">
                                    <label htmlFor="validationDefault01">Email</label>
                                    <input
                                        className={validation.email}
                                        value={values.email}
                                        type="text"
                                        name="email"
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card px-sm-5 px-2 mt-4" style={{ borderColor: "#e3e3e3" }}>
                        <span className="text-center my-2" style={{ Color: "#e3e3e3", fontSize: "17px" }}>
                            Delivery Details
                        </span>
                        <hr className=" mt-0 mx-3" style={{ borderColor: "#e3e3e3" }} />
                        <div className="card-body">
                            <div className="form-row ">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <label htmlFor="">Destination</label>
                                    <DropdownList
                                        id="selectbox"
                                        data={["Home", "Office"]}
                                        defaultValue={"Home"}
                                        onChange={(destination) => setDeldetails({ ...deldetails, destination })}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6  mb-3 mx-auto">
                                    <label htmlFor="validationDefault01">House Number</label>
                                    <input
                                        value={
                                            deldetails.destination === "Home"
                                                ? deldetails.houseNo
                                                : deldetails.houseNoOffice
                                        }
                                        onChange={onValChange}
                                        className="form-control"
                                        type="text"
                                        name={deldetails.destination === "Home" ? "houseNo" : "houseNoOffice"}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <label htmlFor="validationDefault02">Street</label>
                                    <input
                                        className={validation.street}
                                        value={
                                            deldetails.destination === "Home"
                                                ? deldetails.street
                                                : deldetails.streetOffice
                                        }
                                        onChange={onValChange}
                                        type="text"
                                        name={deldetails.destination === "Home" ? "street" : "streetOffice"}
                                    />
                                </div>

                                <div className="col-lg-6 col-md-6 mb-3 ">
                                    <label htmlFor="validationDefault01" className="mb-0">
                                        City
                                    </label>
                                    <DropdownList
                                        className={validation.city}
                                        value={
                                            deldetails.destination === "Home" ? deldetails.city : deldetails.cityOffice
                                        }
                                        onChange={(city) => {
                                            const price = locations.price[locations.city.indexOf(city)];
                                            setDeldetails(
                                                deldetails.destination === "Home"
                                                    ? { ...deldetails, city, delCharge: subtotal < 1000 ? price : 0 }
                                                    : {
                                                          ...deldetails,
                                                          cityOffice: city,
                                                          delChargeOffice: subtotal < 1000 ? price : 0,
                                                      }
                                            );
                                        }}
                                        filter
                                        data={locations.city}
                                        style={{
                                            paddingLeft: "0px",
                                            paddingRight: "0px",
                                        }}
                                    />
                                </div>

                                {/* Landmark */}
                                {/* <div className="col-lg-8 col-md-12 mb-3 mx-auto ">
                  {<label htmlFor="validationDefault01">Landmark</label>}
                  <input
                    value={locationNotification}
                    name="landmark"
                    type="text"
                    className="form-control"
                    placeholder="Click here to set your location on map"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={onValChange}
                    width="300px"
                    data-toggle="modal"
                    data-target="#vaveMapPDCOut"
                  />
                </div> */}
                            </div>

                            {/* Delivery Instructions */}
                            <div className="form-row justify-content-center ">
                                <div className="col-12 mb-3">
                                    <label htmlFor="validationDefault02">Pickup & Delivery Instruction</label>
                                    <textarea
                                        className="form-control"
                                        value={values.delIns}
                                        onChange={(e) => setValues({ ...values, delIns: e.target.value })}
                                        type="text"
                                        name="delIns"
                                        rows="2"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row px-3 justify-content-between">
                        <div className="col-md-8 col-12 pl-0 pr-md-4 pr-0">
                            <div className="card my-4 col-12 px-lg-5 px-md-3" style={{ borderColor: "#e3e3e3" }}>
                                <span className="text-center my-2" style={{ Color: "#e3e3e3", fontSize: "17px" }}>
                                    Online Delivery Timeline
                                </span>
                                <hr className=" mt-0 mx-3" style={{ borderColor: "#e3e3e3" }} />
                                <div className="card-body px-0">
                                    <div className="row">
                                        <div className="col-sm-6 col-12 mb-3 mb-sm-0 text-center checkout-card-collection-date-1">
                                            <p className="pl-2">
                                                <b>Pickup Date</b>
                                            </p>
                                            <DatePicker
                                                selected={values.pickupDate}
                                                onChange={updateDate}
                                                customInput={<ExampleCustomInput />}
                                                dateFormat="MMMM d, yyyy"
                                                minDate={new Date().setDate(new Date().getDate() + 1)}
                                            />
                                        </div>
                                        <div className="col-sm-6 col-12 text-center checkout-card-collection-date-2">
                                            <p className="pl-2">
                                                <b>Delivery Date</b>
                                            </p>
                                            <DatePicker
                                                selected={values.deliveryDate}
                                                onChange={(date) =>
                                                    setValues((values) => ({
                                                        ...values,
                                                        deliveryDate: date,
                                                    }))
                                                }
                                                customInput={<ExampleCustomInput />}
                                                dateFormat="MMMM d, yyyy"
                                                minDate={minDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="card mb-4 mt-md-4 mt-sm-0 col-md-4 col-12 px-4"
                            style={{ borderColor: "#e3e3e3" }}
                        >
                            <span className="text-center my-2" style={{ Color: "#e3e3e3", fontSize: "17px" }}>
                                Payment Details
                            </span>
                            <hr className=" mt-0 mx-3" style={{ borderColor: "#e3e3e3" }} />
                            <div className="card-body col-md-12 ">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="payment"
                                        id="online"
                                        value="online"
                                        onClick={() => setDelPayMethod("Online")}
                                        defaultChecked
                                    />
                                    <label className="form-check-label" htmlFor="online">
                                        Pay online
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="payment"
                                        id="onDelivery"
                                        value="delivery-cash"
                                        onClick={() => setDelPayMethod(delPayMethod === "Card" ? "Card" : "Cash")}
                                    />
                                    <label className="form-check-label" htmlFor="onDelivery">
                                        Pay on delivery
                                    </label>
                                    {delPayMethod !== "Online" && (
                                        <>
                                            <div className="form-check ">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="paydelivery"
                                                    id="cash"
                                                    value="Cash"
                                                    onClick={() => setDelPayMethod("Cash")}
                                                    defaultChecked
                                                />
                                                <label className="form-check-label" htmlFor="cash">
                                                    Cash
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="paydelivery"
                                                    id="card"
                                                    value="Card"
                                                    onClick={() => setDelPayMethod("Card")}
                                                />
                                                <label className="form-check-label" htmlFor="card">
                                                    Card
                                                </label>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="card px-sm-5 px-2 form-right-summary col-lg-3  col-12 col-sm-12 justify-content-center  ml-5"
                    style={{ border: "1px solid #e3e3e3" }}
                >
                    <span className="text-center my-2" style={{ Color: "#e3e3e3", fontSize: "17px" }}>
                        Washing Basket Total (LKR)
                    </span>
                    <hr className=" mt-0 mx-3" style={{ borderColor: "#e3e3e3" }} />
                    <div className="table-responsive">
                        <table id="add-row" className="display table table-striped shadow-none p-0">
                            <tbody>
                                <tr>
                                    <td className="col align-middle">Sub Total</td>
                                    <td className="col align-middle text-right">{parseInt(subtotal).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td className="col align-middle">Delivery Charge</td>
                                    <td className="col align-middle text-right">
                                        {parseInt(
                                            deldetails.destination === "Home"
                                                ? deldetails.delCharge
                                                : deldetails.delChargeOffice
                                        ).toFixed(2)}
                                    </td>
                                </tr>
                                {/* <tr>
                  <td className="col align-middle">Discount</td>
                  <td className="col align-middle text-right">
                    {(discount * 0.01 * subtotal).toFixed(2)}
                  </td>
                </tr> */}
                                <tr>
                                    <td className="col align-middle">Grand Total</td>
                                    <td className="col align-middle text-right">
                                        {parseInt(subtotal) !== 0
                                            ? (
                                                  parseInt(
                                                      deldetails.destination === "Home"
                                                          ? deldetails.delCharge
                                                          : deldetails.delChargeOffice
                                                  ) + parseInt(subtotal)
                                              ).toFixed(2)
                                            : "0.00"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="w-100 px-3 p-md-0">
                        <VaveButton01 onClick={() => history.push("/")} title="Update Basket" />
                    </div>
                    <div className="px-3 p-md-0" style={{ marginBottom: "70px" }}>
                        {!loading ? (
                            orders.length !== 0 ? (
                                <VaveButton01 onClick={placeOrder} title=" Place Order " />
                            ) : (
                                ""
                            )
                        ) : (
                            <Spinner animation="border" style={{ color: "#00468b", marginLeft: "44%" }} />
                        )}
                    </div>
                </div>
            </div>
            <Modal
                backdrop="static"
                show={show}
                onHide={handleOk}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>
                            Thank You {values.firstName} {values.lastName}
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row  pl-sm-5 pl-3">
                        <div className="col-12 mt-3 p-0">
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                                <i className="fas fa-check-circle text-success" style={{ fontSize: "25px" }}></i> &nbsp;
                                Your order has been placed
                            </span>
                        </div>
                    </div>
                    <div className="row summery-modal-mobile-medquery">
                        <div className="col-sm-12 py-3 px-sm-5 pl-3">
                            <p>Order ID : {genId}</p>
                            <p>
                                Collection Date :{" "}
                                {`${
                                    months[values.pickupDate.getMonth()]
                                } ${values.pickupDate.getDate()} ${values.pickupDate.getFullYear()}`}
                            </p>
                            <p>
                                Delivery Date :{" "}
                                {`${
                                    months[values.deliveryDate.getMonth()]
                                } ${values.deliveryDate.getDate()} ${values.deliveryDate.getFullYear()}`}
                            </p>
                            <p>
                                Address :
                                {` ${
                                    deldetails.destination === "Home"
                                        ? deldetails.houseNo
                                        : deldetails.houseNoOffice
                                        ? deldetails.destination === "Home"
                                            ? deldetails.houseNo
                                            : deldetails.houseNoOffice
                                        : ""
                                }
               , ${deldetails.destination === "Home" ? deldetails.street : deldetails.streetOffice}, ${
                                    deldetails.destination === "Home" ? deldetails.city : deldetails.cityOffice
                                }.`}
                            </p>
                            <p>Mobile Number : {values.phoneNumber}</p>
                        </div>
                        <div className="col-sm-12 table-media-query">
                            <table className="table table-striped table-hover rounded table-media-query">
                                <thead className="d-flex">
                                    <th className="col-sm-2 col-4  text-center" scope="col">
                                        Item
                                    </th>
                                    <th className="col-sm-2 d-sm-block d-none  text-center" scope="col">
                                        Washing Method
                                    </th>
                                    <th className="col-sm-2 d-sm-block d-none  text-center" scope="col">
                                        Delivery Form
                                    </th>
                                    <th className="col-sm-2  d-sm-block d-none  text-center" scope="col">
                                        Unit Price
                                        <br />
                                        LKR
                                    </th>
                                    <th className="col-sm-2 col-4  text-center" scope="col">
                                        Quantity
                                    </th>
                                    <th className="col-sm-2 col-4  text-right" scope="col">
                                        Sub Total
                                        <br />
                                        LKR
                                    </th>
                                </thead>
                                <tbody style={{ overflowY: "auto" }}>
                                    {orders.map((item, index) => (
                                        <tr key={index} className="d-flex">
                                            <td className="col-sm-2 col-4 text-center">{item.item}</td>
                                            <td className="col-sm-2 d-sm-block d-none text-center">
                                                {item.washingmethod}
                                            </td>
                                            <td className="col-sm-2 d-sm-block d-none text-center">
                                                {item.deliveryform}
                                            </td>
                                            <td className="col-sm-2 d-sm-block d-none text-center" align="middle">
                                                {parseInt(item.price).toFixed(2)}
                                            </td>
                                            <td className="col-sm-2 col-4 text-center" align="middle">
                                                {item.quantity}
                                            </td>
                                            <td className="col-sm-2 col-4 text-right" align="right">
                                                {parseInt(item.subtot).toFixed(2)}
                                            </td>
                                        </tr>
                                    ))}
                                    <tr className="d-flex">
                                        <th className="col-4" scope="row"></th>
                                        <th className="col-3"></th>
                                        <th className="col-3"></th>
                                    </tr>
                                    <tr className="d-flex">
                                        <th className="col-sm-6 col-1"></th>
                                        <th className="col-sm-4  col-7" scope="row">
                                            Sub Total
                                        </th>
                                        <td className="col-sm-2 col-4" align="right">
                                            {" "}
                                            {parseInt(subtotal).toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr className="d-flex">
                                        <th className="col-sm-6 col-1"></th>
                                        <th className="col-sm-4  col-7" scope="row">
                                            Delivery Charge
                                        </th>
                                        <td className="col-sm-2 col-4" align="right">
                                            {parseInt(values.delCharge).toFixed(2)}
                                        </td>
                                    </tr>
                                    {/* <tr className="d-flex">
                    <th className="col-sm-6 col-1"></th>
                    <th className="col-sm-4  col-7" scope="row">
                      Discount
                    </th>
                    <td className="col-sm-2 col-4" align="right">
                      {(
                        parseInt(subtotal) *
                        parseInt(values.discount) *
                        0.01
                      ).toFixed(2)}
                    </td>
                  </tr> */}
                                    <tr className="d-flex">
                                        <th className="col-sm-6 col-1"></th>
                                        <th className="col-sm-4  col-7" scope="row">
                                            Grand Total
                                        </th>
                                        <td className="col-sm-2 col-4" align="right">
                                            {(parseInt(values.delCharge) + parseInt(subtotal)) /* *
                          (1 - parseInt(values.discount) * 0.01) */
                                                .toFixed(2)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleOk}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            <div
                className="modal fade bd-example-modal-lg"
                id="vaveMapPDCOut"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Select your delivery location
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body vaveMapsCustomStype">
                            {/* {values.landmark.lat ? (
                <Map
                  google={props.google}
                  style={{ width: "95%", height: "95%" }}
                  className={"map"}
                  zoom={16}
                  onClick={onClick}
                  initialCenter={values.landmark}
                >
                  <Marker position={values.landmark} />
                </Map>
              ) : (
                <div>
                  <CircularProgress />
                </div>
              )} */}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                data-dismiss="modal"
                                className="btn btn-primary"
                                onClick={() => setlocationNotification("Location has selected")}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyDMuS1Q6F7P8R5rrn7lq1u036uOXGfPAL0",
})(Form);
