import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../components/AuthContext/AuthContext";
import { OrderContext } from "../../../components/OrderContext/OrderContext";
import Records from "../../../components/Records/Records";
import VaveButton01 from "../../../components/VaveButton01/VaveButton01";
import firebase, { auth, db } from "../../../fbConfig/fbConfig";
import "./WashingBasket.css";

const WashingBasket = (props) => {
    const history = useHistory();

    const [orders, setOrders] = useContext(OrderContext);

    const [subtotal, setSubtotal] = useState(0);

    const [delCharge, setDelCharge] = useState(0);
    const [charge, setCharge] = useState(0);

    const [discount] = useState(0);

    const user = useContext(AuthContext);

    useEffect(() => {
        setOrders(JSON.parse(sessionStorage.getItem("order_details") ? sessionStorage.getItem("order_details") : "[]"));

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let total = 0;
        orders.forEach((x) => {
            total += x.subtot;
        });
        setSubtotal(total);
        setDelCharge(total < 1000 ? charge : 0);
        sessionStorage.setItem("order_details", orders.length !== 0 ? JSON.stringify(orders) : "[]");
    }, [orders]);

    const toMain = () => history.push("/");

    const toCheckout = async () => {
        let user = firebase.auth().currentUser;
        if (user) {
            const doc = await db.collection("customer").doc(user.uid).get();
            if (doc.exists) {
                history.push("/pickup/checkout");
            } else {
                auth.signOut();
                props.setShow(true);
            }
        } else props.setShow(true);
    };

    useEffect(() => {
        if (user && user !== "INIT") {
            firebase
                .firestore()
                .collection("customer")
                .doc(user.uid)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        firebase
                            .firestore()
                            .collection("location")
                            .where("city", "==", doc.data().city)
                            .get()
                            .then((set) => {
                                const charge = parseInt(set.docs[0].data().price);
                                setCharge(charge);
                                const subtotal = orders.reduce((prev, curr) => prev + curr.subtot, 0);
                                setDelCharge(subtotal < 1000 ? charge : 0);
                            })
                            .catch(console.log);
                    }
                })
                .catch(console.log);
        } else {
            setDelCharge(null);
        }
    }, [user]);

    return (
        <div className="container-fluid">
            <div
                className="washing-basket-header"
                style={{
                    textAlign: "center",
                    fontSize: "18px",
                    color: "#727272",
                    padding: "20px 0px",
                    backgroundColor: "#f5f5f5",
                }}
            >
                <i className="fas fa-shopping-basket mr-3" style={{ fontSize: "25px" }}></i>
                Your Washing Basket
            </div>
            <div
                className="row washing-basket-main"
                style={{
                    backgroundColor: "white",
                    padding: "0% 2%",
                }}
            >
                <div className="col-lg-9 col-md-8" style={{ marginTop: "40px", fontSize: "18px" }}>
                    Item Details
                    <hr
                        style={{
                            marginTop: "10px",
                            width: "100%",
                            color: "#707070",
                            borderWidth: "2px",
                        }}
                    ></hr>
                    {subtotal === 0 ? (
                        <div className=" mt-5 text-center">No items currently selected. </div>
                    ) : (
                        <Records />
                    )}
                </div>
                <div className="col-lg-3 col-md-4">
                    <div style={{ marginTop: "40px", fontSize: "18px" }}>
                        Washing Basket Total (LKR)
                        <hr
                            style={{
                                marginTop: "10px",
                                width: "100%",
                                color: "#707070",
                                borderWidth: "2px",
                            }}
                        ></hr>
                    </div>
                    <div className="table-responsive">
                        <table id="add-row" className="display table table-striped shadow-none p-0">
                            <tbody>
                                <tr>
                                    <td className="col align-middle">Sub Total</td>
                                    <td className="col align-middle text-right">{subtotal.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td className="col align-middle">Delivery Charge</td>
                                    <td className="col align-middle text-right">
                                        {delCharge === 0
                                            ? "0.00"
                                            : delCharge
                                            ? `${delCharge.toFixed(2)}`
                                            : "City not selected"}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="col align-middle">Grand Total</td>
                                    <td className="col align-middle text-right">
                                        {subtotal !== 0
                                            ? (subtotal + delCharge - discount * 0.01 * subtotal).toFixed(2)
                                            : "0.00"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="w-100 pr-5 pl-5 p-md-0">
                        <VaveButton01 onClick={toMain} title="Update Basket" />
                    </div>
                    <div className="w-100 pr-5 pl-5 p-md-0 mb-5 mb-sm-0">
                        {orders.length !== 0 && <VaveButton01 onClick={toCheckout} title="Proceed to Checkout" />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WashingBasket;
