import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import favicon from "../../assets/images/favourite.png";
import "./SimpleSlider.css";

const NextArrow = ({ className, style, onClick }) => (
    <svg
        className={"bi bi-chevron-right" + className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="grey"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
        />
    </svg>
);

const PrevArrow = ({ className, style, onClick }) => (
    <svg
        style={{ ...style, display: "block", marginLeft: "5px" }}
        onClick={onClick}
        className={"bi bi-chevron-left" + className}
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="grey"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
        />
    </svg>
);

const SimpleSlider = (props) => {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 7,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 6,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 5,
                },
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    return (
        <Slider {...settings}>
            <div className={props.category == "Favourites" ? "background" : ""}>
                <img
                    style={{ margin: "auto", cursor: "pointer" }}
                    id="Favourites"
                    onClick={(e) => props.updateCategory(e.target.id)}
                    src={favicon}
                    height="50px"
                    alt="favourites"
                    className={props.category == "Favourites" ? "" : "SliderIconHover"}
                />
                <p style={{ fontSize: "14px", textAlign: "center" }}>Favourites</p>
            </div>
            <div className={props.category == "Gents casual wear" ? "background" : ""}>
                <img
                    style={{ margin: "auto", cursor: "pointer" }}
                    id="Gents casual wear"
                    onClick={(e) => props.updateCategory(e.target.id)}
                    src="https://firebasestorage.googleapis.com/v0/b/laundromat-online-portal.appspot.com/o/lmatWeb%2FitemCategoryImages%2FGents%20casual%20wear%20.png?alt=media&token=40bb80e6-8045-45a9-848c-da02cf6acef4"
                    height="50px"
                    className={props.category == "Gents casual wear" ? "" : "SliderIconHover"}
                    alt="gents casual wear"
                />
                <p style={{ fontSize: "14px", textAlign: "center", padding: "0 5px" }}>Gents Casual Wear</p>
            </div>
            <div className={props.category == "Gents formal wear" ? "background" : ""}>
                <img
                    style={{ margin: "auto", cursor: "pointer" }}
                    id="Gents formal wear"
                    onClick={(e) => props.updateCategory(e.target.id)}
                    src="https://firebasestorage.googleapis.com/v0/b/laundromat-online-portal.appspot.com/o/lmatWeb%2FitemCategoryImages%2FGents%20formal%20wear.png?alt=media&token=cc9c5dde-20b6-43d5-96b4-4217cb592844"
                    height="50px"
                    className={props.category == "Gents formal wear" ? "" : "SliderIconHover"}
                    alt="gents formal wear"
                />
                <p style={{ fontSize: "14px", textAlign: "center", padding: "0 5px" }}>Gents Formal Wear</p>
            </div>
            <div className={props.category == "Ladies casual wear" ? "background" : ""}>
                <img
                    style={{ margin: "auto", cursor: "pointer" }}
                    id="Ladies casual wear"
                    onClick={(e) => props.updateCategory(e.target.id)}
                    src="https://firebasestorage.googleapis.com/v0/b/laundromat-online-portal.appspot.com/o/lmatWeb%2FitemCategoryImages%2FLadies%20casual%20wear.png?alt=media&token=191fdacb-5995-4346-b85e-b82a1a39fc44"
                    height="50px"
                    className={props.category == "Ladies casual wear" ? "" : "SliderIconHover"}
                    alt="ladies casual wear"
                />
                <p style={{ fontSize: "14px", textAlign: "center", padding: "0 5px" }}>Ladies Casual Wear</p>
            </div>
            <div className={props.category == "Ladies formal wear" ? "background" : ""}>
                <img
                    style={{ margin: "auto", cursor: "pointer" }}
                    id="Ladies formal wear"
                    onClick={(e) => props.updateCategory(e.target.id)}
                    src="https://firebasestorage.googleapis.com/v0/b/laundromat-online-portal.appspot.com/o/lmatWeb%2FitemCategoryImages%2FLadies%20formal%20wear.png?alt=media&token=87389a9b-75bd-46af-bd80-47f87b94b641"
                    height="50px"
                    className={props.category == "Ladies formal wear" ? "" : "SliderIconHover"}
                    alt="ladies formal wear"
                />
                <p style={{ fontSize: "14px", textAlign: "center", padding: "0 5px" }}>Ladies Formal Wear</p>
            </div>
            <div className={props.category == "Kids" ? "background" : ""}>
                <img
                    style={{ margin: "auto", cursor: "pointer" }}
                    id="Kids"
                    onClick={(e) => props.updateCategory(e.target.id)}
                    src="https://firebasestorage.googleapis.com/v0/b/laundromat-online-portal.appspot.com/o/lmatWeb%2FitemCategoryImages%2FKids.png?alt=media&token=44d4113d-29c8-49d1-b76c-e27a65ed14c2"
                    height="50px"
                    className={props.category == "Kids" ? "" : "SliderIconHover"}
                    alt="kids"
                />
                <p style={{ fontSize: "14px", textAlign: "center", padding: "0 5px" }}>Kids</p>
            </div>
            <div className={props.category == "Uniform" ? "background" : ""}>
                <img
                    style={{ margin: "auto", cursor: "pointer" }}
                    id="Uniform"
                    onClick={(e) => props.updateCategory(e.target.id)}
                    src="https://firebasestorage.googleapis.com/v0/b/laundromat-online-portal.appspot.com/o/lmatWeb%2FitemCategoryImages%2FUniform.png?alt=media&token=90652a72-ebb2-4545-84a9-01e5c42d26b9"
                    height="50px"
                    className={props.category == "Uniform" ? "" : "SliderIconHover"}
                    alt="uniform"
                />
                <p style={{ fontSize: "14px", textAlign: "center", padding: "0 5px" }}>Uniform</p>
            </div>
            <div className={props.category == "Night Ware" ? "background" : ""}>
                <img
                    style={{ margin: "auto", cursor: "pointer" }}
                    id="Night Ware"
                    onClick={(e) => props.updateCategory(e.target.id)}
                    src="https://firebasestorage.googleapis.com/v0/b/laundromat-online-portal.appspot.com/o/lmatWeb%2FitemCategoryImages%2Fnightware.png?alt=media&token=635f30d2-a8a8-4ad7-bb6a-24fca4020aa2"
                    height="50px"
                    className={props.category == "Night Ware" ? "" : "SliderIconHover"}
                    alt="night ware"
                />
                <p style={{ fontSize: "14px", textAlign: "center", padding: "0 5px" }}>Nightwear</p>
            </div>
            <div className={props.category == "Accessories" ? "background" : ""}>
                <img
                    style={{ margin: "auto", cursor: "pointer" }}
                    id="Accessories"
                    onClick={(e) => props.updateCategory(e.target.id)}
                    src="https://firebasestorage.googleapis.com/v0/b/laundromat-online-portal.appspot.com/o/lmatWeb%2FitemCategoryImages%2FAccessories.png?alt=media&token=0d8b2d97-f662-43a8-8b9c-a4f4f70a0342"
                    height="50px"
                    className={props.category == "Accessories" ? "" : "SliderIconHover"}
                    alt="accessories"
                />
                <p style={{ fontSize: "14px", textAlign: "center", padding: "0 5px" }}>Accessories</p>
            </div>
            <div className={props.category == "Bedding and Bath" ? "background" : ""}>
                <img
                    style={{ margin: "auto", cursor: "pointer" }}
                    id="Bedding and Bath"
                    onClick={(e) => props.updateCategory(e.target.id)}
                    src="https://firebasestorage.googleapis.com/v0/b/laundromat-online-portal.appspot.com/o/lmatWeb%2FitemCategoryImages%2FBedding%20and%20Bath.png?alt=media&token=e8d158b4-66dd-4607-b451-3bc5024a5dfb"
                    height="50px"
                    className={props.category == "Bedding and Bath" ? "" : "SliderIconHover"}
                    alt="bedding and bath"
                />
                <p style={{ fontSize: "14px", textAlign: "center", padding: "0 5px" }}>Bed and Bath Linen</p>
            </div>
            <div className={props.category == "Home" ? "background" : ""}>
                <img
                    style={{ margin: "auto", cursor: "pointer" }}
                    id="Home"
                    onClick={(e) => props.updateCategory(e.target.id)}
                    src="https://firebasestorage.googleapis.com/v0/b/laundromat-online-portal.appspot.com/o/lmatWeb%2FitemCategoryImages%2FHome.png?alt=media&token=7b21b68d-8a54-4e70-8b56-c25ea55cba96"
                    height="50px"
                    className={props.category == "Home" ? "" : "SliderIconHover"}
                    alt="home"
                />
                <p style={{ fontSize: "14px", textAlign: "center", padding: "0 5px" }}>Home</p>
            </div>
        </Slider>
    );
};

export default SimpleSlider;
