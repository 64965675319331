import React, { createContext, useEffect, useState } from "react";
import firebase from "../../fbConfig/fbConfig";

export const AuthContext = createContext();

export const AuthProvider = (props) => {
    const [user, setUser] = useState("INIT");

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            setUser(user);
        });
    }, []);

    return <AuthContext.Provider value={user}>{props.children}</AuthContext.Provider>;
};
