import React from "react";
import { useHistory } from "react-router-dom";
import "./CommonSideNav.css";

const CommonSideNav = (props) => {
    const history = useHistory();

    const [sideNav, setSideNav] = props.useSideNav;

    return (
        <div>
            <div className="customer-common-sidenav" style={{ width: sideNav ? "300px" : "0" }}>
                <div>
                    <div
                        className="customer-account-link"
                        onClick={() => {
                            history.push("./");
                            setSideNav(false);
                        }}
                    >
                        <i className="fas fa-home mr-3"></i>
                        Home
                    </div>
                    <div className="customer-account-link">
                        <i className="fas fa-phone-volume mr-3"></i>
                        <a style={{ textDecoration: "none", color: "#818181" }} href="http://laundromat.lk/contact">
                            Contact us
                        </a>
                    </div>
                    {!props.signedIn ? (
                        <div
                            className="customer-account-link"
                            onClick={() => {
                                props.setShow(true);
                                setSideNav(false);
                            }}
                        >
                            <i className="fas fa-user-circle mr-3"></i>
                            Sign In
                        </div>
                    ) : (
                        <div className="customer-account-link" onClick={props.signOut}>
                            <i className="fas fa-power-off mr-3"></i>
                            Sign Out
                        </div>
                    )}
                </div>
                <div className="customer-account-nav-vl"></div>
            </div>
        </div>
    );
};

export default CommonSideNav;
