import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import "./AlbsCmpMsgModal.css";

const AlbsCmpMsgModal = (props) => {
    return (
        <Modal show={props.show} onHide={props.onHide} animation={true} size="md">
            <Modal.Header closeButton style={{ backgroundColor: props.bgColor, color: props.color }}>
                {props.message == "" ? "Loading..." : "Notification"}
            </Modal.Header>
            <Modal.Body>
                <div className="row text-center justify-content-center">
                    {props.message == "" ? (
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    ) : (
                        props.message
                    )}
                </div>
            </Modal.Body>
            <div hidden={!props.footerShow} className="modal-footer">
                <button onClick={props.onHide} type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">
                    Close
                </button>
                <button onClick={props.confirmBtnOnClick} type="button" className="btn btn-primary btn-sm">
                    Confirm Order
                </button>
            </div>
        </Modal>
    );
};

export default AlbsCmpMsgModal;
