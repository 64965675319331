import React, { useContext, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import { AuthContext } from "./components/AuthContext/AuthContext";
import VaveHeader from "./components/VaveHeader/VaveHeader";
import { auth, db } from "./fbConfig/fbConfig";
import CustomerAccount from "./frontend/CustomerAccount/CustomerAccount";
import LoginModal from "./frontend/LoginModal/LoginModal";
import Form from "./frontend/PickUp/CheckoutPage/form";
import PickupMain from "./frontend/PickUp/PickupMain/PickupMain";
import WashingBasket from "./frontend/PickUp/WashingBasket/WashingBasket";

const App = () => {
    const [sideNav, setSideNav] = useState(false);

    const [show, setShow] = useState(false);

    const user = useContext(AuthContext);

    const [isFirst, setIsFirst] = useState(false);

    const isVerified = async () => {
        if (user && user !== "INIT") {
            const doc = await db.collection("customer").doc(user.uid).get();
            if (!isFirst && !doc.exists) {
                setIsFirst(true);
                auth.signOut();
            }
        } else if (!user) {
            setIsFirst(true);
        }
    };

    useEffect(() => {
        isVerified();
    }, [user]);

    return (
        <div className="deliveryMain_container">
            <VaveHeader useSideNav={[sideNav, setSideNav]} setShow={setShow} />
            <div className="deliveryMain_body">
                <Switch>
                    <Route path="/" exact component={PickupMain} />
                    <Route
                        path="/account"
                        exact
                        render={(props) => <CustomerAccount {...props} useSideNav={[sideNav, setSideNav]} />}
                    />
                    <Route path="/basket" exact render={(props) => <WashingBasket {...props} setShow={setShow} />} />
                    <Route path="/pickup/checkout" exact component={Form} />
                    <Route path="*" exact component={PickupMain} />
                </Switch>
                {show && <LoginModal useShow={[show, setShow]} />}
            </div>
        </div>
    );
};

export default App;
