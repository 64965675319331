import axios from "axios";
import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import firebase, { auth, db } from "../../fbConfig/fbConfig";
import CodeVerification from "./CodeVerification";
import EnterMobile from "./EnterMobile";
import LoginCSS from "./LoginModal.module.css";
import LoginSection from "./LoginSection";
import facebook from "./logos/facebook.svg";
import google from "./logos/google.svg";
import PasswordReset from "./PasswordReset";
import SignupSection from "./SignupSection";

const LoginModal = (props) => {
    const [show, setShow] = props.useShow;

    const [isSignUp, setIsSignUp] = useState(false);

    const [render, setRender] = useState("LOGIN");

    const handleClose = () => setShow(false);

    const renderLogin = () => setRender("LOGIN");
    const renderReset = () => setRender("RESET");
    const renderMobile = () => setRender("MOBILE");
    const renderCode = () => setRender("CODE");

    const [details, setDetails] = useState();

    const [verifier, setVerifier] = useState(null);
    const element = useRef(null);
    const [documentId, setDocumentId] = useState();

    const sendSms = (phoneNumber, recaptchaToken) => {
        axios
            .post("/phoneverification/sendsms", {
                phoneNumber: "+94" + phoneNumber.substring(1),
                recaptchaToken,
            })
            .then(({ data }) => setDocumentId(data))
            .catch(console.log);
    };

    const sendVerification = (phoneNumber) => {
        const verifier = new firebase.auth.RecaptchaVerifier(element.current, {
            size: "invisible",
            callback: (recaptchaToken) => {
                sendSms(phoneNumber, recaptchaToken);
            },
        });
        verifier.verify().then(() => setVerifier(verifier));
    };

    const reVerify = (phoneNumber) => {
        verifier.verify().then((recaptchaToken) => {
            sendSms(phoneNumber, recaptchaToken);
        });
    };

    const loginWithProvider = async (provider) => {
        setRender("NONE");
        try {
            const result = await auth.signInWithPopup(provider);
            const doc = await db.collection("customer").doc(result.user.uid).get();
            setRender("NONE");
            if (doc.exists && "phoneNumber" in doc.data()) {
                handleClose();
            } else {
                const { displayName, email } = result.user;
                setDetails({ provider: true, uid: result.user.uid, name: displayName, email });
                renderMobile();
            }
        } catch (error) {
            if (error.code === "auth/popup-closed-by-user") setRender("LOGIN");
            else setRender("NONE");
        }
    };

    const loginGoogle = async () => loginWithProvider(new firebase.auth.GoogleAuthProvider());

    const loginFacebook = async () => loginWithProvider(new firebase.auth.FacebookAuthProvider());

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size={(render === "CODE" || render === "MOBILE") && "sm"}
        >
            <Modal.Body style={{ padding: 0 }}>
                {render === "NONE" && (
                    <div className={LoginCSS.empty_container}>
                        <Spinner animation="border" style={{ color: "#00468b" }} />
                    </div>
                )}

                {render === "RESET" && <PasswordReset renderLogin={renderLogin} />}

                {render === "MOBILE" && (
                    <EnterMobile
                        renderLogin={renderLogin}
                        sendVerification={sendVerification}
                        renderCode={renderCode}
                        setDetails={setDetails}
                    />
                )}

                {render === "CODE" && (
                    <CodeVerification
                        handleClose={handleClose}
                        documentId={documentId}
                        details={details}
                        verifier={verifier}
                        reVerify={reVerify}
                    />
                )}

                {render === "LOGIN" && (
                    <div className={LoginCSS.auth_container}>
                        <div className={LoginCSS.tab_list}>
                            <span className={LoginCSS.close_icon} onClick={handleClose}>
                                <i className="fas fa-times"></i>
                            </span>
                            <div
                                className={`${LoginCSS.tab_item} ${isSignUp && LoginCSS.tab_shadow}`}
                                onClick={() => setIsSignUp(true)}
                            >
                                Sign up
                            </div>
                            <div
                                className={`${LoginCSS.tab_item} ${!isSignUp && LoginCSS.tab_shadow}`}
                                onClick={() => setIsSignUp(false)}
                            >
                                Log in
                            </div>
                        </div>
                        <div className={LoginCSS.tab_content}>
                            <div className={LoginCSS.tab_content}>
                                {isSignUp ? (
                                    <SignupSection
                                        renderCode={renderCode}
                                        sendVerification={sendVerification}
                                        setDetails={setDetails}
                                    />
                                ) : (
                                    <LoginSection renderReset={renderReset} handleClose={handleClose} />
                                )}

                                <div>
                                    <div className={`${LoginCSS.social_login_label} mb-3`}>
                                        <div className={LoginCSS.horizontal_line}></div>
                                        <span className={LoginCSS.connect_with}>or connect with</span>
                                    </div>
                                    <div className={LoginCSS.social_button_wrap}>
                                        <div className={LoginCSS.social_button}>
                                            <img
                                                src={google}
                                                className={LoginCSS.social_img}
                                                role="button"
                                                onClick={loginGoogle}
                                            />
                                        </div>
                                        {/* <div className={LoginCSS.social_button}>
                                            <img
                                                src={facebook}
                                                className={LoginCSS.social_img}
                                                role="button"
                                                onClick={loginFacebook}
                                            />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div ref={element}></div>
            </Modal.Body>
        </Modal>
    );
};

export default LoginModal;
