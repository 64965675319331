import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Spinner from "react-bootstrap/Spinner";
import firebase, { auth, db } from "../../fbConfig/fbConfig";
import LoginCSS from "./LoginModal.module.css";

const CodeVerification = ({ handleClose, documentId, details, reVerify }) => {
    const [verificationCode, setVerificationCode] = useState("");
    const [error, setError] = useState(false);
    const [spinner, setSpinner] = useState(false);

    const [time, setTime] = useState(60);

    useEffect(() => {
        const timer = setInterval(() => {
            setTime((time) => time - 1);
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const resetTimer = () => {
        reVerify(details.phoneNumber);
        setTime(60);
    };

    const verify = async () => {
        setSpinner(true);
        const { data } = await axios.post("/phoneverification/verifysms", {
            verificationCode,
            documentId,
        });

        if (!data) {
            setError(true);
        } else {
            const { provider, name, email, phoneNumber, duplicate } = details;
            let uid;
            if (!provider) {
                await auth.setPersistence(firebase.auth.Auth.Persistence[details.remember ? "LOCAL" : "SESSION"]);
                const userCredential = await auth.createUserWithEmailAndPassword(details.email, details.password);
                uid = userCredential.user.uid;
            } else uid = details.uid;

            const docData = duplicate ? duplicate.data() : null;

            await db
                .collection("customer")
                .doc(uid)
                .set(
                    {
                        phoneNumber,
                        houseNo: "",
                        street: "",
                        city: "",
                        houseNoOffice: "",
                        streetOffice: "",
                        cityOffice: "",
                        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
                        ...docData,
                        name,
                        email,
                    },
                    { merge: true }
                );
            duplicate && db.collection("customer").doc(duplicate.id).delete();
            handleClose();
        }

        setSpinner(false);
    };

    return (
        <div style={{ padding: 30 }}>
            <div className="position-relative text-center mb-3" style={{ fontSize: 18 }}>
                Enter verification code
            </div>
            <FormControl
                className="mb-3"
                // placeholder="Enter verification code"
                name="code"
                isInvalid={error}
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">Invalid verification code</Form.Control.Feedback>
            <div className="d-flex justify-content-between">
                <Button
                    style={{ width: 86 }}
                    className={LoginCSS.log_button}
                    disabled={!(time <= 0)}
                    onClick={resetTimer}
                >
                    {time <= 0 ? "Resend" : "00 : " + time}
                </Button>
                {!spinner ? (
                    <Button className={LoginCSS.log_button} onClick={verify}>
                        Verify
                    </Button>
                ) : (
                    <Spinner animation="border" style={{ color: "#00468b" }} />
                )}
            </div>
        </div>
    );
};

export default CodeVerification;
