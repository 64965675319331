import React, { useEffect, useState } from "react";
import "./AccountDeliveryRow.css";

const AccountDeliveryRow = (props) => {
    const [show, setShow] = useState(false);

    const customDateFormat = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const [rowData, setRowData] = useState(["", "", ""]);

    useEffect(() => {
        switch (props.type) {
            case "delivery-ongoing":
                setRowData([
                    customDateFormat(props.orderRow.createdAt.toDate()),
                    customDateFormat(props.orderRow.deliveryDate.toDate()),
                ]);
                break;
            case "delivery-completed":
                setRowData([
                    customDateFormat(props.orderRow.createdAt.toDate()),
                    customDateFormat(props.orderRow.orderDeliveredAt.toDate()),
                ]);
                break;
            default:
                setRowData(["", "", ""]);
                break;
        }
    }, []);

    return (
        <>
            <div className="row custom-table-striped-tr">
                <div className="col-1 custom-table-striped-td">{props.index + 1}</div>
                <div className="col custom-table-striped-td">{props.orderRow.generatedId}</div>
                <div className="col custom-table-striped-td d-none d-sm-block">{rowData[0]}</div>
                <div className="col custom-table-striped-td d-none d-sm-block">{rowData[1]}</div>
                <div className="col-1 custom-table-striped-td">
                    <i
                        className={`fas fa-chevron-circle-${!show ? "down" : "up"}`}
                        role="button"
                        onClick={() => setShow(!show)}
                    ></i>
                </div>
            </div>
            {show && (
                <div className="row">
                    <div className="col-sm-3 col-lg-6"></div>
                    <div className="col-sm-9 col-lg-6 container custom-table-striped">
                        <div className="row custom-table-striped-th font-weight-bold">
                            <div className="col-1 custom-table-striped-td">#</div>
                            <div className="col custom-table-striped-td">Order</div>
                            <div className="col custom-table-striped-td text-right">Amount</div>
                        </div>
                        {props.orderRow.item.map((obj, index) => (
                            <div className="row custom-table-striped-tr" key={index.toString()}>
                                <div className="col-1 custom-table-striped-td">{index + 1}</div>
                                <div className="col custom-table-striped-td">{obj.CO_Number}</div>
                                <div className="col custom-table-striped-td text-right">{obj.NetAmount}</div>
                            </div>
                        ))}
                        <div className="row">
                            <div className="col-xl-4"></div>
                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col">Total Orders</div>
                                    <div className="col text-right">{props.orderRow.item.length}</div>
                                </div>
                                <div className="row">
                                    <div className="col">Total Amount</div>
                                    <div className="col text-right">{props.orderRow.subtot}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AccountDeliveryRow;
