import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import headerLogo from "../../assets/images/logo/white-logo.png";
import firebase from "../../fbConfig/fbConfig";
import { AuthContext } from "../AuthContext/AuthContext";
import CommonSideNav from "../CommonSideNav/CommonSideNav";
import { OrderContext } from "../OrderContext/OrderContext";
import "./VaveHeader.css";

const VaveHeader = (props) => {
    const history = useHistory();
    const location = useLocation();

    const [orders] = useContext(OrderContext);

    const cart4 = (
        <svg
            className="bi bi-basket basket-icon"
            width="25px"
            height="25px"
            viewBox="0 0 16 16"
            fill="#ffffff"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                d="M10.243 1.071a.5.5 0 0 1 .686.172l3 5a.5.5 0 1 1-.858.514l-3-5a.5.5 0 0 1 .172-.686zm-4.486 0a.5.5 0 0 0-.686.172l-3 5a.5.5 0 1 0 .858.514l3-5a.5.5 0 0 0-.172-.686z"
            />
            <path
                fillRule="evenodd"
                d="M1 7v1h14V7H1zM.5 6a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5H.5z"
            />
            <path
                fillRule="evenodd"
                d="M14 9H2v5a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V9zM2 8a1 1 0 0 0-1 1v5a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V9a1 1 0 0 0-1-1H2z"
            />
            <path
                fillRule="evenodd"
                d="M4 10a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3a.5.5 0 0 1 .5-.5z"
            />
        </svg>
    );

    const [total, setTotal] = useState(0);

    useEffect(() => {
        let total = 0;
        orders.forEach((x) => {
            total += x.quantity;
        });
        setTotal(total);
    }, [orders]);

    const signOut = async () => {
        await firebase.auth().signOut();
        history.push("/");
    };

    const [sideNav, setSideNav] = props.useSideNav;

    const user = useContext(AuthContext);
    const [signedIn, setSignedIn] = useState(false);

    useEffect(() => {
        if (user) setSignedIn(true);
        else setSignedIn(false);
    }, [user]);

    return (
        <div className="vaveHeader_main" style={{ backgroundColor: "#004b90", color: "white" }}>
            <div className="vaveHeader_left">
                <span className="text-primary mx-3 vave-header-sidenav">
                    <i
                        className="fas fa-bars"
                        style={{ color: "white", fontSize: 20 }}
                        onClick={() => setSideNav(!sideNav)}
                    ></i>
                </span>
                {location.pathname !== "/account" && sideNav && (
                    <CommonSideNav
                        useSideNav={[...props.useSideNav]}
                        setShow={props.setShow}
                        signedIn={signedIn}
                        signOut={signOut}
                    />
                )}
                <img alt="logo" className="vaveHeader_logo_img" src={headerLogo} />
                <div className="vaveHeader_title">
                    <p className="vaveHeader_title_text" style={{ color: "white" }}>
                        Online Web Portal
                    </p>
                </div>
            </div>
            <div className="vaveHeader_right ">
                <div className="vaveHeader_button ">
                    <span
                        role="button"
                        style={{ textDecoration: "none", color: "white" }}
                        onClick={() => history.push("/")}
                    >
                        Home
                    </span>
                </div>
                <div className="vaveHeader_button ">
                    <a style={{ textDecoration: "none", color: "white" }} href="http://laundromat.lk/contact">
                        Contact us
                    </a>
                </div>

                {!signedIn ? (
                    <div className="vaveHeader_button ">
                        <span
                            className="vave-header-sign"
                            onClick={() => props.setShow(true)}
                            style={{ color: "white" }}
                        >
                            Sign In
                        </span>
                    </div>
                ) : (
                    <>
                        <div className="vaveHeader_button ">
                            <span className="vave-header-sign" onClick={signOut} style={{ color: "white" }}>
                                Sign Out
                            </span>
                        </div>
                        <i
                            className="fas fa-user-circle fa-2x "
                            style={{ overflow: "none" }}
                            role="button"
                            onClick={() => history.push("/account")}
                        ></i>
                    </>
                )}

                <div className="vaveHeader_button_forbasket ">
                    <Link to="/basket" style={{ textDecoration: "none" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {cart4}
                            <span className="vave-basket-counter">{total}</span>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default VaveHeader;
