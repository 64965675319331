import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import { db } from "../../fbConfig/fbConfig";
import LoginCSS from "./LoginModal.module.css";

const EnterMobile = ({ sendVerification, renderCode, setDetails }) => {
    const [phoneNumber, setPhoneNumber] = useState("");

    const [error, setError] = useState("");

    const [spinner, setSpinner] = useState(false);

    const sendSms = async () => {
        setSpinner(true);
        let error = "";
        if (!/^[0][7][01245678][0-9]{7}$/.test(phoneNumber)) error = "Invalid mobile number";

        const result = await db.collection("customer").where("phoneNumber", "==", phoneNumber).get();
        const duplicate = !result.empty ? result.docs[0] : null;

        if (!error) {
            setDetails((details) => ({ ...details, phoneNumber, duplicate }));
            sendVerification(phoneNumber);
            setSpinner(false);
            renderCode();
        } else {
            setError(error);
            setSpinner(false);
        }
    };
    return (
        <div style={{ padding: 30 }}>
            <div className="position-relative text-center mb-3" style={{ fontSize: 18 }}>
                Enter mobile number
            </div>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className="fas fa-phone-alt"></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    placeholder="Mobile number"
                    name="phoneNumber"
                    isInvalid={error}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </InputGroup>
            <div className="d-flex justify-content-end mb-3">
                {!spinner ? (
                    <Button className={LoginCSS.log_button} onClick={sendSms}>
                        Send verification
                    </Button>
                ) : (
                    <Spinner animation="border" style={{ color: "#00468b" }} />
                )}
            </div>
        </div>
    );
};

export default EnterMobile;
