import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import AccountDeliveryRow from "../AccountDeliveryRow/AccountDeliveryRow";
import "./AccountHomeDel.css";

const AccountHomeDel = (props) => {
    return (
        <div>
            <div>
                <div className="customer-account-link customer-account-title">
                    <i className="fas fa-truck mr-3"></i>
                    Home Delivery Only
                </div>
                <div className="container">
                    <Tabs defaultActiveKey="ongoing" id="uncontrolled-tab-example">
                        <Tab eventKey="ongoing" title="Ongoing Orders">
                            {props.orders.filter((obj) => !obj.deliveryComplete).length === 0 ? (
                                <div className="row mt-5 text-center">
                                    <div
                                        className="col justify-content-center"
                                        style={{
                                            fontSize: "18px",
                                            color: "#818181",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        No ongoing orders.
                                    </div>
                                </div>
                            ) : (
                                <div className="container custom-table-striped">
                                    <div className="row custom-table-striped-th font-weight-bold">
                                        <div className="col-1 custom-table-striped-td">#</div>
                                        <div className="col custom-table-striped-td">Order Number</div>
                                        <div className="col custom-table-striped-td d-none d-sm-block">
                                            Ordered Date
                                        </div>
                                        <div className="col custom-table-striped-td d-none d-sm-block">
                                            Delivery Date
                                        </div>
                                        <div className="col-1 custom-table-striped-td"></div>
                                    </div>
                                    {props.orders
                                        .filter((obj) => !obj.deliveryComplete)
                                        .map((obj, index) => (
                                            <AccountDeliveryRow
                                                type="delivery-ongoing"
                                                orderRow={{ ...obj }}
                                                key={index.toString()}
                                                index={index}
                                            />
                                        ))}
                                </div>
                            )}
                        </Tab>
                        <Tab eventKey="completed" title="Delivered Orders">
                            {props.orders.filter((obj) => obj.deliveryComplete).length === 0 ? (
                                <div className="row mt-5 text-center">
                                    <div
                                        className="col justify-content-center"
                                        style={{
                                            fontSize: "18px",
                                            color: "#818181",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        No delivered orders.
                                    </div>
                                </div>
                            ) : (
                                <div className="container custom-table-striped">
                                    <div className="row custom-table-striped-th font-weight-bold">
                                        <div className="col-1 custom-table-striped-td">#</div>
                                        <div className="col custom-table-striped-td">Order Number</div>
                                        <div className="col custom-table-striped-td d-none d-sm-block">
                                            Ordered Date
                                        </div>
                                        <div className="col custom-table-striped-td d-none d-sm-block">
                                            Delivered Date
                                        </div>
                                        <div className="col-1 custom-table-striped-td"></div>
                                    </div>
                                    {props.orders
                                        .filter((obj) => obj.deliveryComplete)
                                        .map((obj, index) => (
                                            <AccountDeliveryRow
                                                type="delivery-completed"
                                                orderRow={{ ...obj }}
                                                key={index.toString()}
                                                index={index}
                                            />
                                        ))}
                                </div>
                            )}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default AccountHomeDel;
