let firebaseConfig;
let baseURL;
let sandbox;
let merchant_id;

if (process.env.NODE_ENV === "production") {
    firebaseConfig = {
        apiKey: "AIzaSyBoyeR6xfRy4kw6XwAi-w8Kk-PZsHm0PFA",
        authDomain: "laundromat-online-portal.firebaseapp.com",
        databaseURL: "https://laundromat-online-portal.firebaseio.com",
        projectId: "laundromat-online-portal",
        storageBucket: "laundromat-online-portal.appspot.com",
        messagingSenderId: "1071445187048",
        appId: "1:1071445187048:web:6804b10f907310ede06c5d",
        measurementId: "G-5TR5J0F21T",
    };
    baseURL = "https://us-central1-laundromat-online-portal.cloudfunctions.net/app";
    sandbox = false;
    merchant_id = "215080";
} else {
    firebaseConfig = {
        apiKey: "AIzaSyBWFNH3l52ZHHZ7NJtVZCZ-XSfrP-4e8jQ",
        authDomain: "laundromat-development.firebaseapp.com",
        projectId: "laundromat-development",
        storageBucket: "laundromat-development.appspot.com",
        messagingSenderId: "593897977706",
        appId: "1:593897977706:web:d404003512be954f3c9713",
        measurementId: "G-H046HY54GD",
    };
    baseURL = "https://us-central1-laundromat-development.cloudfunctions.net/app";
    sandbox = true;
    merchant_id = "1214766";
}

export { firebaseConfig, baseURL, sandbox, merchant_id };
