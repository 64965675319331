import React from "react";
import "./orderitems.css";

function List(props) {
    const Items = props.orders.map((x) => (
        <tr key={x.item}>
            <td>{x.item} </td>
            <td>{x.qty}</td>
        </tr>
    ));

    return (
        <div className="container mx-0 px-0">
            <table className="table-sm table-striped w-100 table-bordered">
                <thead>
                    <tr>
                        <td>ITEM </td>
                        <td>QUANTITY</td>
                    </tr>
                </thead>
                <tbody>{Items}</tbody>
            </table>
        </div>
    );
}

export default List;
